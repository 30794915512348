import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Category: React.FC<any> = ({ handleNext, formData, setFormData }) => {
  const [isNext, setNext] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, category: (event.target as HTMLInputElement).value });
    setNext(true);
  };
  React.useEffect(() => {
    if (formData.category && isNext) {
      setTimeout(function () {
        handleNext();
      }, 100);
    }
  }, [formData.category, isNext, handleNext]);
  return (
    <FormControl fullWidth>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        What specific category do you need?
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="controlled-radio-buttons-group"
        className="custom-radio"
        value={formData.category}
        onChange={handleChange}
      >
        <FormControlLabel value="plumbing" control={<Radio />} label="Plumbing Quote" />
        <FormControlLabel value="appliance" control={<Radio />} label="Appliance" />
        <FormControlLabel value="concrete" control={<Radio />} label="Concrete" />
        <FormControlLabel value="countertops" control={<Radio />} label="Countertops" />
        <FormControlLabel value="decking" control={<Radio />} label="Decking" />
        <FormControlLabel value="electrical" control={<Radio />} label="Electrical" />
        <FormControlLabel value="fencing" control={<Radio />} label="Fencing" />
        <FormControlLabel value="fireplace" control={<Radio />} label="Fireplace" />
        <FormControlLabel value="flooring" control={<Radio />} label="Flooring" />
        <FormControlLabel value="framing" control={<Radio />} label="Framing" />
        <FormControlLabel value="Garage Door" control={<Radio />} label="Garage Door" />
        <FormControlLabel value="Guttering" control={<Radio />} label="Guttering" />
        <FormControlLabel value="Handyman" control={<Radio />} label="Handyman" />
        <FormControlLabel value="Home Inspections" control={<Radio />} label="Home Inspections" />
        <FormControlLabel value="House Cleaning" control={<Radio />} label="House Cleaning" />
        <FormControlLabel value="HVAC" control={<Radio />} label="HVAC" />
        <FormControlLabel value="Insulation" control={<Radio />} label="Insulation" />
        <FormControlLabel value="Landscaping" control={<Radio />} label="Landscaping" />
        <FormControlLabel value="Masonry" control={<Radio />} label="Masonry" />
        <FormControlLabel value="Movers" control={<Radio />} label="Movers" />
        <FormControlLabel value="Painting" control={<Radio />} label="Painting" />
        <FormControlLabel value="Roofing" control={<Radio />} label="Roofing" />
        <FormControlLabel value="Sheetrock" control={<Radio />} label="Sheetrock" />
        <FormControlLabel value="Siding" control={<Radio />} label="Siding" />
        <FormControlLabel value="Swimming Pool" control={<Radio />} label="Swimming Pool" />
        <FormControlLabel value="Wood Repair" control={<Radio />} label="Wood Repair" />
        <FormControlLabel value="Other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>
  );
};

export default Category;
