import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import MyTaskItem from "./MyTaskItem";

const ConsumerWrap = styled.div`
  margin: 50px 0;
`;

const Title = styled.h2`
  color: var(--themeColor);
  font-size: 24px;
  margin-bottom: 25px;
`;

const Consumer = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}my-task`).then((response) => {
      setItems(response.data);
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <ConsumerWrap>
        <Title>My Task List</Title>
        <Grid container spacing={2}>
          {items.length > 0 &&
            items.map((item: any, index: number) => (
              <Grid item md={3} sm={6} xs={12} key={index}>
                <MyTaskItem item={item} />
              </Grid>
            ))}
        </Grid>
      </ConsumerWrap>
    </Container>
  );
};
export default Consumer;
