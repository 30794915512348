import * as React from "react";
import styled from "styled-components";
import Link from "@mui/material/Link";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Rating from "@mui/material/Rating";
import user from "../../assets/img/profile/user.png";

const ReviewWrap = styled.div``;
const ReviewerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Links = styled(Link)`
  font-size: 25px;
  color: black;
`;
const ImageArea = styled.div`
  display: flex;
  align-items: center;
  & img {
    width: auto;
    margin-right: 20px;
  }
  & p {
    margin: 0;
    font-size: 18px;
    color: black;
  }
  & span {
    font-size: 18px;
  }
`;
const RatingWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 35px;
  & .rating {
    color: #fcea46;
    font-size: 35px;
    margin-right: 15px;
  }
  & .review-num {
    font-size: 18px;
    font-weight: 400;
  }
`;
const ReviewArea = styled.div`
  margin-bottom: 15px;

  & .desc {
    @media (max-width: 600px) {
      text-align: justify;
    }
  }
`;

const Review = () => {
  const [value, setValue] = React.useState<number | null>(4);
  return (
    <ReviewWrap>
      <ReviewArea>
        <ReviewerWrap>
          <ImageArea>
            <img src={user} alt="" />
            <div>
              <p>Travis m.</p>
              <span>Walnut Creek, CA</span>
            </div>
          </ImageArea>
          <Links href="#">
            <BiDotsHorizontalRounded />
          </Links>
        </ReviewerWrap>
        <RatingWrap>
          <Rating
            className="rating"
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          <span className="review-num">9/21/2021</span>
        </RatingWrap>
        <p className="desc">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
      </ReviewArea>
      <ReviewArea>
        <ReviewerWrap>
          <ImageArea>
            <img src={user} alt="" />
            <div>
              <p>Travis m.</p>
              <span>Walnut Creek, CA</span>
            </div>
          </ImageArea>
          <Links href="#">
            <BiDotsHorizontalRounded />
          </Links>
        </ReviewerWrap>
        <RatingWrap>
          <Rating
            className="rating"
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          <span className="review-num">9/21/2021</span>
        </RatingWrap>
        <p className="desc">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
      </ReviewArea>
    </ReviewWrap>
  );
};
export default Review;
