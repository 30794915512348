import styled from "@emotion/styled";

export const EditWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
`;
export const EditIcon = styled.button`
  color: #c92b27;
  background: #e9e9e9;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border: 0;
  & .edit-icon {
    color: #c92b27;
    font-size: 22px;
  }
`;
