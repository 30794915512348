export interface IRegister {
  isRegister: boolean;
}
export interface ILogin {
  isAuthencated: boolean;
  isInitialized: boolean;
  user: any;
}

export const UPDATE_REGISTER = "UPDATE_REGISTER";
export const RESET_REGISTER = "RESET_REGISTER";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const RESET_LOGIN = "RESET_LOGIN";

interface IUpdateRegister {
  type: typeof UPDATE_REGISTER;
  payload: IRegister;
}
interface IUpdateLogin {
  type: typeof UPDATE_LOGIN;
  payload: ILogin;
}

export type RegisterType = IUpdateRegister;
export type LoginType = IUpdateLogin;
