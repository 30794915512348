import React from "react";
import axios from "axios";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import Progress from "./Progress";

import { useDispatch, useSelector } from "react-redux";
import { ILogin } from "../store/types";
import { updateLogin, resetLogin } from "../store/action";
import setAuthToken from "../utils/setAuthToken";

const MainArea = styled.main`
  min-height: calc(100vh - 240px);
`;

const Layout: React.FC<any> = ({ children }) => {
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const userData: ILogin = store.login;
  const [loadPage, setLoadPage] = React.useState(false);

  React.useEffect(() => {
    setAuthToken(localStorage.token);
    try {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}auth`)
        .then((response) => {
          dispatch(
            updateLogin({
              ...userData,
              isAuthencated: true,
              isInitialized: false,
              user: response.data,
            })
          );
        })
        .finally(() => setLoadPage(true));
    } catch (error) {
      dispatch(resetLogin());
      setLoadPage(true);
    }
  }, []);
  return (
    <div>
      {loadPage ? (
        <>
          <ScrollToTop />
          <Header page={children.props.location.pathname} />
          <MainArea>{children}</MainArea>
          <Footer />
        </>
      ) : (
        <Progress />
      )}
    </div>
  );
};

export default Layout;
