import * as React from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { styled as styledd } from "@mui/material/styles";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";

const BootstrapDialog = styledd(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const Form = styled.form`
  margin: 20px 0;
`;

const PageLoader = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="model-cus-title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ContractInformationUpdate: React.FC<any> = (props) => {
  const { open, setOpen, user } = props;
  const deafultData = {
    website: user.website,
    phone: user.mobile_phone,
    address: user.business_address,
  };
  const [isLoader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState<any>(deafultData);

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = () => {
    setLoader(true);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const body = new FormData();
    body.append("website", formData.website);
    body.append("phone", formData.phone);
    body.append("address", formData.address);

    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}updateContactInformation`, body, config)
        .then((response) => {
          if (response.data.status) {
            setOpen(false);
            props.authLoadData();
            toast.success(response.data.msg);
          } else {
            response.data.msg.forEach((error: string) => toast.error(error));
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Server error! Please try again.");
        })
        .finally(() => setLoader(false));
    } catch (error: any) {
      const errors = error.response.data.errors;
      setLoader(false);
      toast.error(errors?.data);
    }
  };

  return (
    <div>
      {isLoader && (
        <PageLoader>
          <CircularProgress size={60} thickness={4} color="inherit" />
        </PageLoader>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Contact Information
        </BootstrapDialogTitle>
        <DialogContent>
          <Form>
            <FormGroup>
              <TextField
                label="Website"
                value={formData.website}
                type="url"
                onChange={(e) => setFormData({ ...formData, website: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <TextField
                label="Phone Number"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              />
            </FormGroup>

            <FormGroup>
              <TextField
                label="Address"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              />
            </FormGroup>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="large" onClick={submitForm}>
            Update
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ContractInformationUpdate;
