import React from "react";
import styled from "styled-components";
import { GrPrevious, GrNext } from "react-icons/gr";
import { default as Sliders } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img01 from "../../assets/img/task/t1.png";
import Img02 from "../../assets/img/task/t2.png";
import Img03 from "../../assets/img/task/t3.png";
import Img04 from "../../assets/img/task/t4.png";

const ItemInfo = styled.div`
  width: 100%;
`;
const ItemImage = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin: 15px !important;
  border: 1px solid #bbbbbb;
  @media (max-width: 991px) {
    margin: 10px !important;
  }
  & img {
    width: 100%;
  }
  &::last-child {
    margin-right: 0;
  }
`;
const ItemText = styled.div`
  max-width: 250px;
  margin: 0 auto;
  & .name {
    font-size: 18px;
    color: var(--themeColor);
    font-weight: 700;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 55px;
  }
  & .desc {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 65px;
  }
`;

const SlidersI = styled(Sliders)`
  margin: 0 -15px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: auto;
    margin: 0;
  }
  & .slick-arrow:before {
    z-index: 11;
  }
`;
const Section = styled.section`
  padding: 50px 0px;

  & h4 {
    margin: 0px 0px 20px 0px;
    @media (max-width: 991px) {
      margin: 0;
      padding: 0;
    }
    font-size: 24px;
    color: #727272;
    margin-bottom: 15px;
    font-weight: 400;
  }
`;

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  cssEase: "linear",
  prevArrow: <GrPrevious />,
  nextArrow: <GrNext />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CategoryItem: React.FC<any> = ({ othersItem }) => {
  return (
    <Section>
      <h4>Other Tasks</h4>
      <SlidersI {...settings}>
        {othersItem &&
          othersItem.map((src: any, index: number) => (
            <ItemInfo key={index}>
              <ItemImage>
                <img src={src.video_thumbnail} alt="" />
                <ItemText>
                  <p className="name">{`${src.service_type} ${src.specific_service}`}</p>
                  <p className="desc">{src.description}</p>
                </ItemText>
              </ItemImage>
            </ItemInfo>
          ))}
      </SlidersI>
    </Section>
  );
};
export default CategoryItem;
