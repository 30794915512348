import React from "react";
import styled from "styled-components";
import { EditWrap } from "./style";

const AboutWrap = styled.div`
  & .desc {
    @media (max-width: 600px) {
      text-align: justify;
    }
  }
`;
const ImgArea = styled.div`
  display: flex;
  align-items: center;
  & img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
`;
const NameArea = styled.div`
  margin-left: 20px;
  & p {
    margin: 0;
    font-size: 18px;
    color: black;
    font-weight: 700;
  }
  & span {
  }
`;

const About: React.FC<any> = ({ user }) => {
  return (
    <AboutWrap>
      <EditWrap>
        <ImgArea>
          <img src={user.profile_pic} alt="" />
          <NameArea>
            <p>{`${user.first_name} ${user.last_name}`}</p>
            {user.user_type === "provider" && <span>Business Owner</span>}
          </NameArea>
        </ImgArea>
      </EditWrap>
      <p className="desc">{user.about}</p>
    </AboutWrap>
  );
};
export default About;
