import styled from "styled-components";
import Container from "@mui/material/Container";
import bg from "../../assets/img/TaskList/banner.png";
const Section = styled.section`
  margin-bottom: 20px;
`;
const BannerWrap = styled.section`
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bgColor);
  margin-top: 25px;
  & h2 {
    font-size: 24px;
    font-weight: 700;
    padding: 20px;
    @media (max-width: 475px) {
      text-align: center;
    }
  }
`;

const Banner = () => {
  return (
    <Section>
      <Container maxWidth="xl">
        <BannerWrap>
          <h2>Hi, good morning, user example, we hope you have a good day. </h2>
        </BannerWrap>
      </Container>
    </Section>
  );
};
export default Banner;
