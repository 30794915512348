import React from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ILogin } from "../../store/types";
import { updateLogin } from "../../store/action";
import setAuthToken from "../../utils/setAuthToken";

const Section = styled.div`
  margin-bottom: 50px;
`;
const Title = styled.h2`
  margin: 50px 0 20px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
`;
const InputForm = styled.form`
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 50px;
  padding: 40px 40px 20px;
`;
const FormLable = styled.div`
  margin-bottom: 25px;
`;
const SubButton = styled.div``;
const OtherInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 15px;
  font-size: 14px;
  & p {
    margin: 0;
  }
  & a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  & .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;
const CustLoadingButton = styled(LoadingButton)`
  background-color: #000 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
`;

interface IHeaderProps {
  email: string;
  password: string;
}
const deafultValue = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const userData: ILogin = store.login;

  const [value, setValue] = React.useState<IHeaderProps>(deafultValue);
  const { email, password } = value;
  const [loading, setLoading] = React.useState(false);
  const [isRedirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    if (userData.isAuthencated) {
      setRedirect(true);
    }
  }, [userData.isAuthencated]);
  if (isRedirect) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const formSubmit = async () => {
    if (email === "") {
      toast.error("Email is required.");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Email is not validated!");
      return;
    } else if (password === "") {
      toast.error("Password is required.");
      return;
    }
    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const body = JSON.stringify({ email, password });
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/login`, body, config)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.msg);
            setValue(deafultValue);
            localStorage.setItem("token", response.data.token);
            setAuthToken(response.data.token);
            dispatch(
              updateLogin({
                ...userData,
                isAuthencated: true,
                isInitialized: false,
                user: response.data.user,
              })
            );
          } else {
            const errors: any = response.data.msg;
            if (errors) {
              errors.forEach((error: any) => toast.error(error));
            }
          }
        })
        .catch((err) => {
          toast.error(err.request.statusText);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      console.log(errors);

      if (errors) {
        errors.forEach((error: any) => toast.error(error.msg));
      }
    }
  };

  return (
    <Section>
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={4}>
            <Title>Log in</Title>
            <InputForm
              onSubmit={(e) => {
                e.preventDefault();
                formSubmit();
              }}
            >
              <FormLable>
                <TextField
                  fullWidth
                  label="Enter email"
                  onChange={(e) => setValue({ ...value, email: e.target.value })}
                  value={email}
                  id="email"
                  type="email"
                />
              </FormLable>
              <TextField
                fullWidth
                label="Enter password"
                onChange={(e) => setValue({ ...value, password: e.target.value })}
                value={password}
                id="password"
                type="password"
              />
              <OtherInfo>
                <FormControlLabel value="remember" control={<Checkbox />} label="Remember Me" labelPlacement="end" />
                <Link to="/">Forgot Password?</Link>
              </OtherInfo>
              <SubButton>
                <CustLoadingButton type="submit" loading={loading} variant="contained" size="large" fullWidth>
                  Submit
                </CustLoadingButton>
              </SubButton>
              <OtherInfo>
                <p>Don't have an account ?</p>
                <Link to="/signup/consumer">Sign Up</Link>
              </OtherInfo>
            </InputForm>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
export default Login;
