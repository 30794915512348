import * as React from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { styled as styledd } from "@mui/material/styles";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TimePickerInput from "../../../components/TimePickerInput";

const BootstrapDialog = styledd(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-elevation": {
    maxWidth: "700px",
  },
}));
const Form = styled.form`
  margin: 20px 0;
  & .MuiFormControlLabel-root {
    margin-bottom: 0 !important;
  }
`;

const PageLoader = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="model-cus-title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const LocationHoursModal: React.FC<any> = (props) => {
  const { open, setOpen, items, getServiceName, authLoadData, user, formData, setFormData } = props;
  const [isLoader, setLoader] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = () => {
    setLoader(true);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const hours: any = JSON.stringify([
      formData.monday,
      formData.tuesday,
      formData.wednesday,
      formData.thursday,
      formData.friday,
      formData.saturday,
      formData.sunday,
    ]);
    const body = new FormData();
    body.append("address", formData.address);
    body.append("lat", formData.lat);
    body.append("lng", formData.lng);
    body.append("hours", hours);

    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}updateLoactionHours`, body, config)
        .then((response) => {
          if (response.data.status) {
            setOpen(false);
            getServiceName();
            authLoadData();
            toast.success(response.data.msg);
          } else {
            response.data.msg.forEach((error: string) => toast.error(error));
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Server error! Please try again.");
        })
        .finally(() => setLoader(false));
    } catch (error: any) {
      const errors = error.response.data.errors;
      setLoader(false);
      toast.error(errors?.data);
    }
  };

  return (
    <div>
      {isLoader && (
        <PageLoader>
          <CircularProgress size={60} thickness={4} color="inherit" />
        </PageLoader>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Location & hours
        </BootstrapDialogTitle>
        <DialogContent>
          <Form>
            <FormGroup>
              <label>Your Location</label>
              <Autocomplete
                placeholder="Your Location"
                apiKey={process.env.REACT_APP_MAPS_API_KEY}
                onPlaceSelected={(place) => {
                  setFormData({
                    ...formData,
                    ...{
                      address: place.formatted_address,
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                    },
                  });
                }}
                defaultValue={formData.address}
              />
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.monday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.monday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, monday: { ...formData.monday, start: new Date(time).toUTCString() } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.monday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, monday: { ...formData.monday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, monday: { ...formData.monday, closed: e.target.checked } })
                        }
                        checked={formData.monday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.tuesday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.tuesday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, tuesday: { ...formData.tuesday, start: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.tuesday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, tuesday: { ...formData.tuesday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, tuesday: { ...formData.tuesday, closed: e.target.checked } })
                        }
                        checked={formData.tuesday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.wednesday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.wednesday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, wednesday: { ...formData.wednesday, start: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.wednesday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, wednesday: { ...formData.wednesday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, wednesday: { ...formData.wednesday, closed: e.target.checked } })
                        }
                        checked={formData.wednesday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.thursday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.thursday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, thursday: { ...formData.thursday, start: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.thursday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, thursday: { ...formData.thursday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, thursday: { ...formData.thursday, closed: e.target.checked } })
                        }
                        checked={formData.thursday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.friday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.friday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, friday: { ...formData.friday, start: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.friday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, friday: { ...formData.friday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, friday: { ...formData.friday, closed: e.target.checked } })
                        }
                        checked={formData.friday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.saturday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.saturday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, saturday: { ...formData.saturday, start: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.saturday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, saturday: { ...formData.saturday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, saturday: { ...formData.saturday, closed: e.target.checked } })
                        }
                        checked={formData.saturday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Day</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.sunday.name}
                      label="Age"
                      onChange={(e) => {}}
                      disabled
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="Start Time"
                    value={formData.sunday.start}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, sunday: { ...formData.sunday, start: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TimePickerInput
                    title="End Time"
                    value={formData.sunday.end}
                    setSelect={(time: any) => {
                      setFormData({ ...formData, sunday: { ...formData.sunday, end: time } });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setFormData({ ...formData, sunday: { ...formData.sunday, closed: e.target.checked } })
                        }
                        checked={formData.sunday.closed}
                      />
                    }
                    label="Closed"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "0 0 20px" }}>
          <Button variant="contained" size="large" onClick={submitForm}>
            Update
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default LocationHoursModal;
