import { registerReducer, loginReducer } from "./reducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunkMiddleware from "redux-thunk";

const rootReducer = combineReducers({
  register: registerReducer,
  login: loginReducer,
});

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, middleWareEnhancer);
  return store;
}
