import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import logo from "../assets/img/logo.png";
import Menu from "./Menu";
import AuthMenu from "./AuthMenu";
import MobileMenu from "./MobileMenu";
import { Link } from "react-router-dom";

const Section = styled.header`
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const MainLogo = styled.img`
  width: 215px;
  @media (max-width: 991px) {
    width: 150px;
  }
`;
const MenuArea = styled.div``;
const AuthArea = styled.div``;

interface IHeaderProps {
  page: string;
  isAuthencated: boolean;
}
const MenuWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;
const Mobile = styled.div`
  position: absolute;
  right: 0;
`;

const Header: React.FC<IHeaderProps> = ({ isAuthencated }) => {
  const { innerWidth: width } = window;
  return (
    <Section>
      <Container maxWidth="xl">
        <MenuWrap>
          <Link to={isAuthencated ? "/dashboard" : "/"} className="d-iblock">
            <MainLogo src={logo} alt="" />
          </Link>
          {width > 991 ? (
            <>
              <MenuArea>
                <Menu />
              </MenuArea>
              <AuthArea>
                <AuthMenu />
              </AuthArea>
            </>
          ) : (
            <Mobile>
              <MobileMenu />
            </Mobile>
          )}
        </MenuWrap>
      </Container>
    </Section>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
});
export default connect(mapStateToProps, {})(Header);
