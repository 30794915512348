import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import SocialMedia from "./SocialMedia";

import workerImg from "../assets/img/worker.png";

const Section = styled.div`
  margin: 85px 0;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;
const Title = styled.h1`
  font-size: 64px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--colorAlt);
  position: relative;

  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 900px) {
    text-align: center;
  }
  & span {
    color: var(--color);
  }
  @media (min-width: 900px) {
    :after {
      height: 185px;
      display: block;
      width: 3px;
      content: "";
      position: absolute;
      right: 0;
      top: 8px;
      background-color: var(--color);
    }
  }
`;
const SubTitle = styled.p`
  margin-top: 25px;
  font-size: 24px;
  line-height: 40px;
  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 30px;
  }
  @media (max-width: 900px) {
    text-align: center;
    margin-top: 0;
  }
  & span {
    color: var(--colorAlt);
    font-weight: 600;
  }
`;
const ContentWrapper = styled.div`
  & button {
    background-color: #000;
    border-radius: 40px;
    padding: 15px 40px;
    text-transform: capitalize;
    font-size: 24px;
    width: 300px;
    @media (max-width: 991px) {
      font-size: 18px;
      padding: 10px 35px;
      width: auto;
    }
    &:hover {
      background-color: #302e2e;
    }
  }
`;
const BannerImg = styled.div`
  text-align: center;
  img {
    height: calc(100vh - 270px);
    width: auto;
    @media (max-width: 991px) {
      height: auto;
      width: 50%;
    }
  }
`;
const Socal = styled.div`
  margin: 10px 0 80px;
  @media (max-width: 991px) {
    margin: 10px 0 50px;
  }
`;

const Banner = () => {
  const { innerWidth: width } = window;
  return (
    <Section>
      <Container maxWidth="xl">
        <Grid container spacing={2} className="banner-align">
          <Grid item md={5} sm={12}>
            <ContentWrapper>
              <Title>
                Use video to get
                <br />
                <span>Quotes</span> to Repair
              </Title>
              <SubTitle>
                Your video will be <span>texted</span> to multiple <span>Tulsa-area </span>
                businesses. The <span>free</span> way to get good quotes <span>fast!</span>
              </SubTitle>
              {width > 899 && (
                <>
                  <Socal>
                    <SocialMedia />
                  </Socal>
                  <Button variant="contained">Choose Category</Button>
                </>
              )}
            </ContentWrapper>
          </Grid>
          <Grid item md={1} sm={12}></Grid>
          <Grid item md={6} sm={12}>
            <BannerImg>
              <img src={workerImg} alt="" />
              {width < 900 && (
                <ContentWrapper>
                  <Socal>
                    <SocialMedia />
                  </Socal>
                  <Button variant="contained" className="cat-btn">
                    Choose Category
                  </Button>
                </ContentWrapper>
              )}
            </BannerImg>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
export default Banner;
