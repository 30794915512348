import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IRegister } from "../../../store/types";
import { updateRegister } from "../../../store/action";

// @ts-ignore
import Reveal from "react-reveal/Reveal";

const CustLoadingButton = styled(LoadingButton)`
  background-color: #000 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
`;

const Section = styled.div`
  margin-bottom: 50px;
`;
const Title = styled.h2`
  margin: 50px 0 20px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
`;
const InputForm = styled.form`
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 50px;
  padding: 40px 40px 20px;
`;
const FormLable = styled.div`
  margin-bottom: 25px;
`;
const SubButton = styled.div``;
const OtherInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 15px;
  font-size: 14px;
  & p {
    margin: 0;
  }
  & a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  & .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;
interface IHeaderProps {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  con_password: string;
}

const Consumer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const deafultValue = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    con_password: "",
  };
  const register: IRegister = store.register;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<IHeaderProps>(deafultValue);
  const { first_name, last_name, email, password, con_password } = value;

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const formSubmit = async () => {
    if (first_name === "") {
      toast.error("First name is required.");
      return;
    } else if (last_name === "") {
      toast.error("Lasst name is required.");
      return;
    } else if (email === "") {
      toast.error("Email is required.");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Email is not validated!");
      return;
    } else if (password === "") {
      toast.error("Password is required.");
      return;
    } else if (password.length < 8) {
      toast.error("Password length minimum 8 characters.");
      return;
    } else if (con_password === "") {
      toast.error("Confirm password is required.");
      return;
    } else if (con_password.length < 8) {
      toast.error("Confirm password length minimum 8 characters.");
      return;
    } else if (password !== con_password) {
      toast.error("Password does not match confirm password");
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const body = JSON.stringify({ first_name, last_name, email, password });
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/signup/consumer`, body, config)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.msg);
            setValue(deafultValue);
            dispatch(updateRegister({ ...register, isRegister: true }));
            history.push("/login");
          } else {
            const errors: any = response.data.msg;
            if (errors) {
              errors.forEach((error: any) => toast.error(error));
            }
          }
        })
        .catch((err) => {
          toast.error(err.request.statusText);
        })
        .finally(() => {
          setLoading(false);
          dispatch(updateRegister({ ...register, isRegister: false }));
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error: any) => toast.error(error.msg));
      }
    }
  };
  return (
    <Reveal effect="fadeInUp">
      <InputForm
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
      >
        <FormLable>
          <TextField
            fullWidth
            label="First name"
            value={first_name}
            onChange={(e) => setValue({ ...value, first_name: e.target.value })}
            id="first_name"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Last name"
            value={last_name}
            onChange={(e) => setValue({ ...value, last_name: e.target.value })}
            id="last_name"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setValue({ ...value, email: e.target.value })}
            id="email"
            type="email"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Password"
            value={password}
            onChange={(e) => setValue({ ...value, password: e.target.value })}
            id="password"
            type="password"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Confirm password"
            value={con_password}
            onChange={(e) => setValue({ ...value, con_password: e.target.value })}
            id="con_password"
            type="password"
          />
        </FormLable>
        <SubButton>
          <CustLoadingButton type="submit" loading={loading} variant="contained" size="large" fullWidth>
            Submit
          </CustLoadingButton>
        </SubButton>
        <OtherInfo>
          {/* <p>I've have an account?</p> */}
          <Link to="/login">Log In</Link>
        </OtherInfo>
      </InputForm>
    </Reveal>
  );
};
export default Consumer;
