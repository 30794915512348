import * as React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import Menu from "./Menu";
import AuthMenu from "./AuthMenu";

const MenuArea = styled.div``;

export default function PersistentDrawerLeft() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
      >
        <HiMenuAlt3 size={45} color={"#000"} />
      </IconButton>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: "#000",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        onClick={handleDrawerClose}
      >
        <IconButton>
          <AiOutlineClose color={"#fff"} size={30} />
        </IconButton>
        <MenuArea>
          <Menu />
          <AuthMenu />
        </MenuArea>
      </Drawer>
    </Box>
  );
}
