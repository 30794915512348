import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { AiOutlineCheck } from "react-icons/ai";
import { EditWrap } from "./style";

const MoreInfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    margin-top: 0;
  }
`;
const DeleteItem = styled.div`
  cursor: pointer;
`;

const MoreInfo = () => {
  const [items, setItems] = useState<any>([]);

  const getMoreInformation = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios.get(`${process.env.REACT_APP_BACKEND_URL}getMoreInformation`, config).then((response) => {
      setItems(response.data);
    });
  };
  useEffect(() => {
    getMoreInformation();
  }, []);

  return (
    <div>
      <EditWrap>
        <h4>More information</h4>
      </EditWrap>
      {items.length > 0 &&
        items.map((item: any, index: number) => (
          <MoreInfoWrap key={index}>
            <p>
              <AiOutlineCheck /> &nbsp; {item.data}
            </p>
          </MoreInfoWrap>
        ))}
    </div>
  );
};
export default MoreInfo;
