import * as React from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Address: React.FC<any> = ({ formData, setFormData }) => {
  return (
    <div>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        Address of property
      </FormLabel>
      <FormGroup>
        <TextField
          label="Street Address"
          value={formData.street}
          onChange={(e) => setFormData({ ...formData, street: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="City"
              value={formData.city}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State / Province"
              value={formData.state}
              onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Postal / Zip Code"
              value={formData.zip}
              onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
            />
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
};

export default Address;
