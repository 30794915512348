import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { projectStatusJson } from "./jsonData";

const ProjectStatus: React.FC<any> = ({ handleNext, formData, setFormData }) => {
  const [isNext, setNext] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, projectStatus: (event.target as HTMLInputElement).value });
    setNext(true);
  };
  React.useEffect(() => {
    if (formData.projectStatus && isNext) {
      setTimeout(function () {
        handleNext();
      }, 100);
    }
  }, [formData, isNext, handleNext]);
  return (
    <FormControl fullWidth>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        Status of your project
      </FormLabel>
      {projectStatusJson.length > 0 &&
        projectStatusJson.map((src: any, index) => (
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            className="custom-radio"
            value={formData.projectStatus}
            onChange={handleChange}
            key={index}
          >
            <FormControlLabel value={src.lable} control={<Radio />} label={src.name} />
          </RadioGroup>
        ))}
    </FormControl>
  );
};
export default ProjectStatus;
