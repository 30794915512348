import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ILogin } from "../store/types";
import RequestModal from "./request";

const ItemArea = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  @media (max-width: 991px) {
    display: block;
    text-align: center;
    margin: 0 0 40px;
  }
`;
const Item = styled.li`
  padding: 5px;
  @media (max-width: 991px) {
    padding: 20px;
  }
  & a {
    padding: 0 15px;
    font-size: 20px;
    color: var(--color);
    text-decoration: none;
    @media (max-width: 991px) {
      color: var(--bgColor);
    }
  }
`;

const Menu: React.FC<any> = ({ isAuthencated }) => {
  const store: any = useSelector<any>((state): any => state);
  const userData: ILogin = store.login;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <ItemArea>
      {isAuthencated ? (
        <>
          <Item>
            <Link to="/dashboard">Dashboard</Link>
          </Item>
          {userData.user.user_type === "consumer" && (
            <>
              <Item>
                <a href="/" onClick={handleClickOpen}>
                  Create a Task
                </a>
              </Item>
              <RequestModal open={open} setOpen={setOpen} />
            </>
          )}
          {userData.user.user_type === "provider" && (
            <Item>
              <Link to="/task-list">Services</Link>
              <Link to="/your-leads">Your Leads</Link>
            </Item>
          )}
        </>
      ) : (
        <>
          <Item>
            <Link to="/">Home</Link>
          </Item>
          <Item>
            <Link to="/">About</Link>
          </Item>
          <Item>
            <Link to="/">Services</Link>
          </Item>
          <Item>
            <Link to="/">How it works</Link>
          </Item>
        </>
      )}
    </ItemArea>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
});
export default connect(mapStateToProps, {})(Menu);
