import {
  RegisterType,
  LoginType,
  IRegister,
  ILogin,
  UPDATE_REGISTER,
  RESET_REGISTER,
  UPDATE_LOGIN,
  RESET_LOGIN,
} from "./types";

const register: IRegister = {
  isRegister: false,
};

const login: ILogin = {
  isAuthencated: false,
  isInitialized: true,
  user: [],
};

export function registerReducer(
  state = register,
  action: RegisterType | any
): IRegister {
  switch (action.type) {
    case UPDATE_REGISTER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_REGISTER: {
      return { ...state, ...{ register } };
    }
    default:
      return state;
  }
}

export function loginReducer(state = login, action: LoginType | any): ILogin {
  switch (action.type) {
    case UPDATE_LOGIN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_LOGIN: {
      return { ...state, ...login };
    }
    default:
      return state;
  }
}
