import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled as styledd } from "@mui/material/styles";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Rating from "@mui/material/Rating";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

const BootstrapDialog = styledd(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const Form = styled.form``;
const FormInner = styled.div`
  width: 100%;
`;
const PageLoader = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;
const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="model-cus-title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const deafultData = {
  review: 1,
  comments: "",
};

const ReviewModal: React.FC<any> = (props) => {
  const { open, setOpen, taskId, userId } = props;
  const [isLoader, setLoader] = React.useState(false);
  const [formData, setFormData] = React.useState<any>(deafultData);
  console.log(formData);

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = () => {
    setLoader(true);
    if (formData.comments === "") {
      toast.error("Comments is required.");
      setLoader(false);
      return false;
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const body = new FormData();
    body.append("review", formData.review);
    body.append("comments", formData.comments);
    body.append("taskId", taskId);
    body.append("userId", userId);

    if (formData.images) {
      for (const key of Object.keys(formData.images)) {
        body.append("images[]", formData.images[key]);
      }
    }

    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}saveReview`, body, config)
        .then((response) => {
          if (response.data.status) {
            setOpen(false);
            toast.success(response.data.msg);
            setFormData(deafultData);
          } else {
            toast.error(response.data.msg);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Server error! Please try again.");
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      setLoader(false);
      toast.error(errors?.data);
    }
  };

  return (
    <div>
      {isLoader && (
        <PageLoader>
          <CircularProgress size={60} thickness={4} color="inherit" />
        </PageLoader>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Write a Review
        </BootstrapDialogTitle>
        <DialogContent>
          <Form>
            <FormInner>
              <FormGroup>
                <h3>Review</h3>
                <Rating
                  className="rating"
                  name="size-large"
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, review: newValue });
                  }}
                  defaultValue={1}
                  size="large"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="Write your review"
                  value={formData.comments}
                  onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
                  size="small"
                  multiline
                  rows={7}
                />
              </FormGroup>
            </FormInner>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={submitForm} variant="contained" size="large">
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ReviewModal;
