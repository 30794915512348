import * as React from "react";
import axios from "axios";
import { IMaskInput } from "react-imask";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";

const Form = styled.form`
  padding: 30px;
`;
const FormControl = styled.div`
  margin-bottom: 30px;
`;
const SubmitForm = styled.div`
  text-align: center;
`;
const FormButton = styled(LoadingButton)`
  padding: 15px 40px 12px;
`;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props: any, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // @ts-ignore
      mask="#000-0000-0000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      value={props.value ? props.value : ""}
      placeholder="xxxx-xxxx-xxxx-xxxx"
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

const ExpMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props: any, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // @ts-ignore
      mask="00/0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      value={props.value ? props.value : ""}
      placeholder="MM/YYYY"
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

const CVCMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props: any, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // @ts-ignore
      mask="#00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      value={props.value ? props.value : ""}
      placeholder="123"
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

interface State {
  id: string;
  card_number: string;
  exp: string;
  cvc: string;
}

const FormattedInputs: React.FC<any> = ({ id, handleClose, loadTaskDetails }) => {
  const [values, setValues] = React.useState<State>({
    id: id,
    card_number: "",
    exp: "",
    cvc: "",
  });
  const [loading, setLoading] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const payNow = async (event: any) => {
    event.preventDefault();
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    setLoading(true);
    const body = JSON.stringify(values);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}pay-now`, body, config)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.msg);
        } else {
          toast.error(response.data.msg);
        }
      })
      .finally(() => {
        setLoading(false);
        handleClose();
        loadTaskDetails();
      });
  };

  return (
    <Form onSubmit={payNow}>
      <FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Card number"
              value={values.card_number}
              onChange={handleChange}
              name="card_number"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent: TextMaskCustom as any,
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Expiry"
              value={values.exp}
              onChange={handleChange}
              name="exp"
              InputProps={{
                inputComponent: ExpMaskCustom as any,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="CVC"
              value={values.cvc}
              onChange={handleChange}
              name="cvc"
              InputProps={{
                inputComponent: CVCMaskCustom as any,
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormControl>
      <SubmitForm>
        <FormButton type="submit" loading={loading} variant="contained">
          Pay Now
        </FormButton>
      </SubmitForm>
    </Form>
  );
};
export default FormattedInputs;
