import React from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { BiTimeFive } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import Rating from "@mui/material/Rating";
import Description from "./Description";

const Section = styled.div`
  margin-top: 20px;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
`;
const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;
const PostedWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 667px) {
    display: block;
    text-align: center;
  }
  @media (max-width: 991px) {
    justify-content: center;
  }

  & p {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    color: var(--themeColor);
  }
  & .icon {
    margin-right: 10px;
    color: #2571e2;
    font-size: 25px;
    font-weight: 600;
  }
`;
const NameWrap = styled.div`
  margin-left: 30px;
  & .items {
    display: flex;
    @media (max-width: 667px) {
      justify-content: center;
    }
  }
`;

const ImgWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    margin-right: 0px;
  }
  & img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  span {
    display: block;
    margin-bottom: 10px;
  }
`;

const LocationWrap = styled.div`
  display: flex;
  align-items: start;
  @media (max-width: 667px) {
    justify-content: center;
  }
  & .loc-icon {
    font-size: 40px;
    color: var(--themeColor);
    margin-right: 15px;
    @media (max-width: 667px) {
      font-size: 25px;
      margin-right: 6px;
    }
  }
  & .loc-name {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    color: var(--themeColor);
    margin-bottom: 5px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  & p {
    font-weight: 600;
    font-size: 14px;
    color: #727272;
    margin: 0;
  }
`;

const LocName = styled.div`
  & .link {
    color: #2571e2;
    font-weight: 600;
    text-decoration: none;
    @media (max-width: 767px) {
      display: none;
    }
  }
`;
const ReviewWrap = styled.div`
  & p {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    color: var(--themeColor);
    margin-bottom: 5px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  & .rating {
    color: #fcea46;
  }
`;
const RatingS = styled.div`
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 10px;
  }
`;
const MIButton = styled(Button)`
  margin-right: 15px;
  background-color: #2571e2 !important;
  border: 1px solid #2571e2;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  width: 200px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  color: var(--bgColor);
  text-transform: capitalize;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }
  &:hover {
    background: #2571e2;
  }
`;
const ProfileButton = styled(Button)`
  margin-right: 15px;
  background: #bd2323;
  border: 1px solid #dadada;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  width: 200px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  color: var(--bgColor);
  text-transform: capitalize;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }
  &:hover {
    background: #a30d0d;
  }
`;
const BtnArea = styled.div`
  display: flex;
`;

const BuyerLead: React.FC<any> = ({ lead, userType, task }) => {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <>
      <Section>
        <Container maxWidth="xl">
          <ReviewContainer>
            <PostedWrap>
              <ImgWrap>
                <span>Provider</span>
                <img src={lead.photo} alt="" />
              </ImgWrap>

              <NameWrap>
                <p>{lead.name}</p>
                <div className="items">
                  <BiTimeFive className="icon" /> <span>{lead.postTime}</span>
                </div>
              </NameWrap>
            </PostedWrap>

            <LocationWrap>
              <FaMapMarkerAlt className="loc-icon" />
              <LocName>
                <p className="loc-name">Location</p>
                <p>{lead.address} </p>
                {/* <Link className="link" href="#">
                  View Map
                </Link> */}
              </LocName>
            </LocationWrap>
            <ReviewWrap>
              <p>Reputation</p>
              <RatingS>
                <Rating className="rating" name="size-large" defaultValue={3} size="large" />
              </RatingS>
            </ReviewWrap>
            <BtnArea>
              <ProfileButton href={`/profile/${lead.user_id}`}>View Profile</ProfileButton>
              {userType === "consumer" && (
                <MIButton variant="contained" onClick={() => setShowMore(!showMore)}>
                  More Info
                </MIButton>
              )}
            </BtnArea>
          </ReviewContainer>
        </Container>
      </Section>
      {showMore && <Description task={task} lead={lead} />}
    </>
  );
};
export default BuyerLead;
