import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import map from "../../assets/img/profile/map.png";
import { EditWrap } from "./style";

const LocationWrap = styled.div`
  & img {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const CustGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    display: block;
  }
`;
const AddressWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 320px) {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  & button {
    border: 1px solid #b4b4b4;
    color: var(--color);
    font-size: 18px;
    font-weight: 700;
  }
`;
const LeftAddress = styled.div`
  & p {
    margin: 0;
    color: black;
    font-size: 18px;
  }
`;
const Links = styled(Link)`
  font-size: 18px;
`;
const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
  @media (max-width: 900px) {
    float: none;
    margin-top: 10px;
  }
  & li {
    padding: 10px 0px;
    text-align: left;
    display: flex;
    color: black;
    @media (max-width: 900px) {
      justify-content: space-between;
    }
    & span {
      width: 60px;
      display: block;
      font-weight: 700;
      color: black;
    }
  }
`;

const Location: React.FC<any> = ({ user, authLoadData }) => {
  const [items, setItems] = useState<any>([]);

  const getServiceName = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios.get(`${process.env.REACT_APP_BACKEND_URL}getBusinessHour`, config).then((response) => {
      setItems(response.data);
    });
  };

  useEffect(() => {
    getServiceName();
  }, []);

  return (
    <div>
      <EditWrap>
        <h4>Location & hours</h4>
      </EditWrap>
      <LocationWrap>
        <CustGrid container spacing="6">
          <Grid item md={6} sm={12}>
            <img src={map} alt="" />
            <AddressWrap>
              <LeftAddress>
                <Links href="#">{user.address}</Links>
                <p>Ste A</p>
              </LeftAddress>
              <Button variant="outlined">Get Directions</Button>
            </AddressWrap>
            <span>Walnut Creek, CA 94596</span>
          </Grid>
          <Grid item md={4} sm={12}>
            {items.length > 0 && (
              <UL>
                {items.map((item: any, index: number) => (
                  <li key={index}>
                    <span> Mon </span> 8:00AM - 5:00PM
                  </li>
                ))}
              </UL>
            )}
          </Grid>
        </CustGrid>
      </LocationWrap>
    </div>
  );
};
export default Location;
