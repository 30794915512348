import React, { useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { EditWrap, EditIcon } from "./style";
import AboutUpdateModal from "./modal/AboutUpdateModal";

const AboutWrap = styled.div`
  & .desc {
    @media (max-width: 600px) {
      text-align: justify;
    }
  }
`;
const ImgArea = styled.div`
  display: flex;
  align-items: center;
  & img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
`;
const NameArea = styled.div`
  margin-left: 20px;
  & p {
    margin: 0;
    font-size: 18px;
    color: black;
    font-weight: 700;
  }
  & span {
  }
`;
const ButtonWrap = styled.div`
  margin-top: 30px;
  text-transform: capitalize;

  & button {
    border: 1px solid #b4b4b4;
    color: var(--color);
  }
`;

const About: React.FC<any> = ({ user, authLoadData }) => {
  const [open, setOpen] = useState(false);
  return (
    <AboutWrap>
      <EditWrap>
        <ImgArea>
          <img src={user.profile_pic} alt="" />
          <NameArea>
            <p>{`${user.first_name} ${user.last_name}`}</p>
            {user.user_type === "provider" && <span>Business Owner</span>}
          </NameArea>
        </ImgArea>
        <EditIcon onClick={() => setOpen(true)}>
          <MdOutlineModeEditOutline className="edit-icon" />
        </EditIcon>
      </EditWrap>
      <p className="desc">{user.about}</p>
      {/* <ButtonWrap>
        <Button variant="outlined">Read more</Button>
      </ButtonWrap> */}
      <AboutUpdateModal open={open} setOpen={setOpen} user={user} authLoadData={authLoadData} />
    </AboutWrap>
  );
};
export default About;
