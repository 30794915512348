import React from "react";
import { Redirect } from "react-router";
import Provider from "../task-list/";
import Consumer from "./Consumer";

// @ts-ignore
import Reveal from "react-reveal/Reveal";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const Dashboard: React.FC<any> = ({ isAuthencated, user }) => {
  if (!isAuthencated) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  return <div>{user.user_type === "provider" ? <Provider /> : <Consumer />}</div>;
};
const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
  user: state.login.user,
});
export default connect(mapStateToProps, {})(Dashboard);
