import React from "react";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import FormLabel from "@mui/material/FormLabel";
import { FiTrash2 } from "react-icons/fi";

const FsUpload = styled.div`
  position: relative;
`;
const FsUploadTarget = styled.div`
  background: #fff;
  border: 3px dashed #607d8b;
  border-radius: 2px;
  color: #455a64;
  font-size: 14px;
  margin: 0;
  padding: 25px;
  text-align: center;
  transition: background 0.15s linear, border 0.15s linear, color 0.15s linear, opacity 0.15s linear;
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  background: #fff;
  color: #455a64;
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;
const Preview = styled.div`
  display: flex;
  justify-content: left;
  align-items: start;
  column-gap: 2.5%;
  flex-wrap: wrap;
  margin: 30px 0;
`;
const ImgList = styled.div`
  width: 31.6%;
  position: relative;
`;
const Delete = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  width: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  cursor: pointer;
`;

const Picture: React.FC<any> = ({ handleNext, formData, setFormData }) => {
  const [countFile, setCount] = React.useState(0);
  const [images, setImages] = React.useState<any>();
  const [imagesAll, setImagesAll] = React.useState<any>([]);

  React.useEffect(() => {
    if (images) {
      imagesAll.push(images);
      setImagesAll(imagesAll);
    }
  }, [images]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (imagesAll.length > 0) {
      setFormData({
        ...formData,
        images: imagesAll,
      });
    }
  }, [imagesAll, images]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeImage = (id: number) => {
    const imageList = formData.images;
    imageList.splice(id, 1);
    setFormData({
      ...formData,
      images: imageList,
    });
    setCount(countFile - 1);
  };
  return (
    <div>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        Take pictures to better describe your project
      </FormLabel>
      <FsUpload>
        <FsUploadTarget>Drag and drop picture or click to select</FsUploadTarget>
        <Input
          type="file"
          name="images"
          onChange={(e: any) => {
            if (countFile < 5) {
              setImages(e.target.files[0]);
              setCount(countFile + 1);
            } else {
              toast.error("You can upload max five picture.");
            }
          }}
          accept="image/png, image/gif, image/jpeg"
        />
      </FsUpload>
      <Preview>
        {Object.values(formData.images).length > 0 &&
          Object.values(formData.images).map((img: any, index: number) => (
            <ImgList key={index}>
              <img src={URL.createObjectURL(img)} alt="" />
              <Delete onClick={() => removeImage(index)}>
                <FiTrash2 size={20} color="#fff" />
              </Delete>
            </ImgList>
          ))}
      </Preview>
    </div>
  );
};

export default Picture;
