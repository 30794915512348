import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PublicRoute, PrivateRoute } from "./Routes";
import Home from "./containers/home";
import Login from "./containers/auth/Login";
import Profile from "./containers/profile";
import PublicProfile from "./containers/public-profile";
import TaskList from "./containers/task-list";
import Signup from "./containers/auth/Signup";
import { toast } from "react-toastify";
import configureStore from "./store";
import YourLeads from "./containers/your-leads";
import Lead from "./containers/lead-details";
import LeadDetails from "./containers/lead-details";
import Dashboard from "./containers/dashboard";
import TaskCreate from "./containers/task-create";
import setAuthToken from "./utils/setAuthToken";

import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  bodyClassName: "govyr--toast-body",
  draggable: false,
  position: toast.POSITION.TOP_RIGHT,
});

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const store = configureStore();

  return (
    <Router>
      <Provider store={store}>
        <Switch>
          <PublicRoute path="/" exact component={Home} />
          <PublicRoute path="/login" exact component={Login} />
          <PublicRoute path="/signup/:type" exact component={Signup} />
          <PrivateRoute path="/your-leads" exact component={YourLeads} />
          <PrivateRoute path="/lead" exact component={Lead} />
          <PrivateRoute path="/lead/:id" exact component={LeadDetails} />
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
          {/* <PrivateRoute path="/task-create" exact component={TaskCreate} /> */}
          <PrivateRoute path="/profile" exact component={Profile} />
          <PrivateRoute path="/profile/:id" exact component={PublicProfile} />
          <PrivateRoute path="/task-list" exact component={TaskList} />
          <PublicRoute path="*">
            <p> Page not found</p>
          </PublicRoute>
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
