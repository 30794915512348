import React, { useState } from "react";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Redirect } from "react-router";
import Consumer from "./type/Consumer";
import Provider from "./type/Provider";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const Section = styled.div`
  padding-bottom: 50px;
`;
const Title = styled.h2`
  margin: 50px 0 20px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
`;

const LinkArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LinkTab = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: var(--color);
  display: block;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  &.active {
    background-color: #000;
  }
`;

const Signup: React.FC<any> = ({ isAuthencated, location }) => {
  if (isAuthencated) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <Section>
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item md={4}>
            <Title>Sign up</Title>
            <LinkArea>
              <LinkTab to="/signup/consumer" className={location.pathname === "/signup/consumer" ? "active" : ""}>
                Consumer
              </LinkTab>
              <LinkTab to="/signup/provider" className={location.pathname === "/signup/provider" ? "active" : ""}>
                Provider
              </LinkTab>
            </LinkArea>

            <Switch>
              <Route path="/signup/consumer" render={() => <Consumer />} />
              <Route path="/signup/provider" render={() => <Provider />} />
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

Signup.propTypes = {
  login: PropTypes.func.isRequired,
};
const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
});
export default connect(mapStateToProps, {})(Signup);
