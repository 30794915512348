import styled from "styled-components";
import { BsYoutube, BsFacebook, BsInstagram } from "react-icons/bs";

const UL = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  column-gap: 25px;
  @media (max-width: 900px) {
    justify-content: center;
  }
  & a {
    width: 80px;
    height: 80px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @media (max-width: 991px) {
      width: 70px;
      height: 70px;
    }
    &.instagram {
      border-color: #e1306c;
      & svg {
        font-size: 32px;
        color: #e1306c;
        @media (max-width: 991px) {
          font-size: 28px;
        }
      }
    }
    &.youtube {
      border-color: #ff0000;
      & svg {
        color: #ff0000;
        font-size: 42px;
        @media (max-width: 991px) {
          font-size: 36px;
        }
      }
    }
    &.facebook {
      border-color: #4267b2;
      & svg {
        color: #4267b2;
        font-size: 46px;
        @media (max-width: 991px) {
          font-size: 40px;
        }
      }
    }
  }
`;

const SocialMedia = () => {
  return (
    <UL>
      <li>
        <a href="/" target="_blank" className="instagram" rel="noreferrer">
          <BsInstagram />
        </a>
      </li>
      <li>
        <a href="/" target="_blank" className="youtube" rel="noreferrer">
          <BsYoutube />
        </a>
      </li>
      <li>
        <a href="/" target="_blank" className="facebook" rel="noreferrer">
          <BsFacebook />
        </a>
      </li>
    </UL>
  );
};
export default SocialMedia;
