import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import { AiOutlineCheck } from "react-icons/ai";
import { MdDeleteOutline, MdOutlineAdd } from "react-icons/md";
import { EditWrap, EditIcon } from "./style";
import MoreInformationModal from "./modal/MoreInformationModal";

const MoreInfoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    margin-top: 0;
  }
`;
const DeleteItem = styled.div`
  cursor: pointer;
`;

const MoreInfo = () => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<any>([]);

  const getMoreInformation = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios.get(`${process.env.REACT_APP_BACKEND_URL}getMoreInformation`, config).then((response) => {
      setItems(response.data);
    });
  };
  useEffect(() => {
    getMoreInformation();
  }, []);

  const deleteMoreInformation = (id: number) => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios.get(`${process.env.REACT_APP_BACKEND_URL}removeMoreInformation/${id}`, config).then((response) => {
      if (response.data.status) {
        getMoreInformation();
        toast.success(response.data.msg);
      } else {
        response.data.msg.forEach((error: string) => toast.error(error));
      }
    });
  };

  return (
    <div>
      <EditWrap>
        <h4>More information</h4>
        <EditIcon onClick={() => setOpen(true)}>
          <MdOutlineAdd className="edit-icon" />
        </EditIcon>
      </EditWrap>
      {items.length > 0 &&
        items.map((item: any, index: number) => (
          <MoreInfoWrap key={index}>
            <p>
              <AiOutlineCheck /> &nbsp; {item.data}
            </p>
            <DeleteItem onClick={() => deleteMoreInformation(item.id)}>
              <MdDeleteOutline size={20} color="#c92b27" />
            </DeleteItem>
          </MoreInfoWrap>
        ))}
      <MoreInformationModal open={open} setOpen={setOpen} getMoreInformation={getMoreInformation} />
    </div>
  );
};
export default MoreInfo;
