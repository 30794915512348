import React from "react";
import styled from "@emotion/styled";
import FormLabel from "@mui/material/FormLabel";

const FsUpload = styled.div`
  position: relative;
`;
const FsUploadTarget = styled.div`
  background: #fff;
  border: 3px dashed #607d8b;
  border-radius: 2px;
  color: #455a64;
  font-size: 14px;
  margin: 0;
  padding: 25px;
  text-align: center;
  transition: background 0.15s linear, border 0.15s linear, color 0.15s linear, opacity 0.15s linear;
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  background: #fff;
  color: #455a64;
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const Video: React.FC<any> = ({ handleNext, formData, setFormData }) => {
  const handleChange = (event: any) => {
    setFormData({ ...formData, video: event.target.files[0] });
  };
  return (
    <div>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        Take a video in LANDSCAPE mode to better describe your project.
      </FormLabel>
      <FsUpload>
        <FsUploadTarget>Drag and drop Video or click to select</FsUploadTarget>
        <Input type="file" name="images" onChange={handleChange} accept="video/mp4,video/x-m4v,video/*" />
      </FsUpload>
      {formData.video && (
        <video src={URL.createObjectURL(formData.video)} controls style={{ width: "100%", marginTop: "15px" }} />
      )}
    </div>
  );
};

export default Video;
