import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import SocialMedia from "./SocialMedia";

const Footers = styled.footer`
  padding: 25px 0;
  background-color: var(--colorAlt);
`;
const FooterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    margin-bottom: 25px;
  }
`;

const Social = styled.div``;
const Copy = styled.p`
  font-size: 24px;
  color: var(--bgColor);
  margin: 16px 0;
  @media (max-width: 991px) {
    font-size: 18px;
  }
`;

const Footer = () => {
  return (
    <Footers>
      <Container maxWidth="xl">
        <FooterWrap>
          <Copy>Copyright © 2021 Tulsadigs | All Rights Reserved</Copy>
          {/* <Social>
            <SocialMedia />
          </Social> */}
        </FooterWrap>
      </Container>
    </Footers>
  );
};

export default Footer;
