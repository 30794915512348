import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { IRegister } from "../../../store/types";
import { updateRegister } from "../../../store/action";

// @ts-ignore
import Reveal from "react-reveal/Reveal";

const CustLoadingButton = styled(LoadingButton)`
  background-color: #000 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
`;

const Section = styled.div`
  margin-bottom: 50px;
`;
const Title = styled.h2`
  margin: 50px 0 20px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
`;
const InputForm = styled.form`
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 50px;
  padding: 40px 40px 20px;
`;
const FormLable = styled.div`
  margin-bottom: 25px;
`;
const SubButton = styled.div``;
const OtherInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 15px;
  font-size: 14px;
  & p {
    margin: 0;
  }
  & a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  & .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;
interface IHeaderProps {
  company_name: string;
  contact_person: string;
  business_address: string;
  business_phone: string;
  mobile_phone: string;
  website: string;
  category: string;
  email: string;
  password: string;
  con_password: string;
}

const Provider = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const deafultValue = {
    company_name: "",
    contact_person: "",
    business_address: "",
    business_phone: "",
    mobile_phone: "",
    category: "",
    website: "",
    email: "",
    password: "",
    con_password: "",
  };
  const register: IRegister = store.register;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<IHeaderProps>(deafultValue);
  const {
    company_name,
    contact_person,
    business_address,
    business_phone,
    mobile_phone,
    website,
    category,
    email,
    password,
    con_password,
  } = value;

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const formSubmit = async () => {
    if (company_name === "") {
      toast.error("Company name is required.");
      return;
    } else if (contact_person === "") {
      toast.error("Contact Person is required.");
      return;
    } else if (business_address === "") {
      toast.error("Business address is required.");
      return;
    } else if (category === "") {
      toast.error("<C></C>ategory address is required.");
      return;
    } else if (business_phone === "") {
      toast.error("Business phone is required.");
      return;
    } else if (mobile_phone === "") {
      toast.error("Mobile phone is required.");
      return;
    } else if (email === "") {
      toast.error("Email is required.");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Email is not validated!");
      return;
    } else if (password === "") {
      toast.error("Password is required.");
      return;
    } else if (password.length < 8) {
      toast.error("Password length minimum 8 characters.");
      return;
    } else if (con_password === "") {
      toast.error("Confirm password is required.");
      return;
    } else if (con_password.length < 8) {
      toast.error("Confirm password length minimum 8 characters.");
      return;
    } else if (password !== con_password) {
      toast.error("Password does not match confirm password");
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const body = JSON.stringify({
        company_name,
        contact_person,
        business_address,
        business_phone,
        mobile_phone,
        category,
        website,
        email,
        password,
      });
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/signup/provider`, body, config)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.msg);
            setValue(deafultValue);
            dispatch(updateRegister({ ...register, isRegister: true }));
            history.push("/login");
          } else {
            const errors: any = response.data.msg;
            if (errors) {
              errors.forEach((error: any) => toast.error(error));
            }
          }
        })
        .catch((err) => {
          toast.error(err.request.statusText);
        })
        .finally(() => {
          setLoading(false);
          dispatch(updateRegister({ ...register, isRegister: false }));
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error: any) => toast.error(error.msg));
      }
    }
  };
  return (
    <Reveal effect="fadeInUp">
      <InputForm
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
      >
        <FormLable>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              id="category"
              value={category}
              label="Category"
              onChange={(e) => setValue({ ...value, category: e.target.value })}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Appliance">Appliance</MenuItem>
              <MenuItem value="Concrete">Concrete</MenuItem>
              <MenuItem value="Countertops">Countertops</MenuItem>
              <MenuItem value="Decking">Decking</MenuItem>
              <MenuItem value="Electrical">Electrical</MenuItem>
              <MenuItem value="Fencing">Fencing</MenuItem>
              <MenuItem value="Fireplace">Fireplace</MenuItem>
              <MenuItem value="Flooring">Flooring</MenuItem>
              <MenuItem value="Framing">Framing</MenuItem>
              <MenuItem value="Garage Door">Garage Door</MenuItem>
              <MenuItem value="Guttering">Guttering</MenuItem>
              <MenuItem value="Handyman">Handyman</MenuItem>
              <MenuItem value="Home Inspections">Home Inspections</MenuItem>
              <MenuItem value="House Cleaning">House Cleaning</MenuItem>
              <MenuItem value="HVAC">HVAC</MenuItem>
              <MenuItem value="Insulation">Insulation</MenuItem>
              <MenuItem value="Landscaping">Landscaping</MenuItem>
              <MenuItem value="Masonry">Masonry</MenuItem>
              <MenuItem value="Movers">Movers</MenuItem>
              <MenuItem value="Painting">Painting</MenuItem>
              <MenuItem value="Plumbing">Plumbing</MenuItem>
              <MenuItem value="Roofing">Roofing</MenuItem>
              <MenuItem value="Sheetrock">Sheetrock</MenuItem>
              <MenuItem value="Siding">Siding</MenuItem>
              <MenuItem value="Swimming Pool">Swimming Pool</MenuItem>
              <MenuItem value="Wood Repair">Wood Repair</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </FormLable>
        {/* <FormLable>
          <TextField
            fullWidth
            label="Category"
            value={category}
            onChange={(e) => setValue({ ...value, category: e.target.value })}
            id="category"
            type="text"
          />
        </FormLable> */}
        <FormLable>
          <TextField
            fullWidth
            label="Company Name"
            value={company_name}
            onChange={(e) => setValue({ ...value, company_name: e.target.value })}
            id="company_name"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Contact Person"
            value={contact_person}
            onChange={(e) => setValue({ ...value, contact_person: e.target.value })}
            id="contact_person"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Business Address"
            value={business_address}
            onChange={(e) => setValue({ ...value, business_address: e.target.value })}
            id="business_address"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Business Phone"
            value={business_phone}
            onChange={(e) => setValue({ ...value, business_phone: e.target.value })}
            id="business_phone"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Mobile Phone"
            value={mobile_phone}
            onChange={(e) => setValue({ ...value, mobile_phone: e.target.value })}
            id="mobile_phone"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Website"
            value={website}
            onChange={(e) => setValue({ ...value, website: e.target.value })}
            id="website"
            type="text"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setValue({ ...value, email: e.target.value })}
            id="email"
            type="email"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Password"
            value={password}
            onChange={(e) => setValue({ ...value, password: e.target.value })}
            id="password"
            type="password"
          />
        </FormLable>
        <FormLable>
          <TextField
            fullWidth
            label="Confirm password"
            value={con_password}
            onChange={(e) => setValue({ ...value, con_password: e.target.value })}
            id="con_password"
            type="password"
          />
        </FormLable>
        <SubButton>
          <CustLoadingButton type="submit" loading={loading} variant="contained" size="large" fullWidth>
            Submit
          </CustLoadingButton>
        </SubButton>
        <OtherInfo>
          {/* <p>I've have an account?</p> */}
          <Link to="/login">Log In</Link>
        </OtherInfo>
      </InputForm>
    </Reveal>
  );
};
export default Provider;
