import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GrPrevious, GrNext } from "react-icons/gr";
import { default as Sliders } from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const ItemInfo = styled(Link)``;
const ItemImage = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 25px !important;
  @media (max-width: 475px) {
    margin-right: 0px !important;
  }
  &::last-child {
    margin-right: 0;
  }
`;
const ItemText = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  & h4 {
    margin-top: 45%;
    font-size: 24px;
    margin-bottom: 0;
    color: var(--bgColor);
    text-transform: uppercase;
    font-weight: 700;
  }
  & p {
    margin: 5px;
    color: #d6cb76;
    font-size: 18px;
  }
`;

const SlidersI = styled(Sliders)`
  @media (max-width: 767px) {
    max-width: 95%;
    margin: auto;
  }
  & .slick-arrow:before {
    z-index: 11;
  }
`;

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  cssEase: "linear",
  prevArrow: <GrPrevious />,
  nextArrow: <GrNext />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CategoryItem: React.FC<any> = (props) => {
  const [items, setItems] = React.useState([]);
  useEffect(() => {
    axios
      .post("http://localhost:8000/api/getCategories")
      .then((res) => {
        setItems(res.data.category);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <SlidersI {...settings}>
      {items.length > 0 &&
        items.map((item: any, index: number) => (
          <ItemInfo to={"/project/" + item.slug} key={index}>
            <ItemImage>
              <img src={item.image} alt="" />
              <ItemText>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </ItemText>
            </ItemImage>
          </ItemInfo>
        ))}
    </SlidersI>
  );
};
export default CategoryItem;
