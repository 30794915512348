import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";
import { useSelector } from "react-redux";
import { ILogin } from "../store/types";

const ItemArea = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;
const Item = styled.li`
  padding: 0 10px;
  & a {
    padding: 0 15px;
    font-size: 16px;
    color: var(--color);
    font-weight: 600;
    text-decoration: none;
    &.login {
      background-color: #bdbdbd;
      padding: 8px 25px 10px;
      border-radius: 35px;
    }
    &.signup {
      background-color: var(--color);
      padding: 8px 25px 10px;
      border-radius: 35px;
      color: var(--bgColor);
    }
  }
`;

const AuthMenu = () => {
  const store: any = useSelector<any>((state): any => state);
  const userData: ILogin = store.login;

  return (
    <ItemArea>
      {userData.isAuthencated ? (
        <Item>
          <UserMenu />
        </Item>
      ) : (
        <>
          <Item>
            <Link to="/login" className="login">
              Log In
            </Link>
          </Item>
          <Item>
            <Link to="/signup/consumer" className="signup">
              Sign Up
            </Link>
          </Item>
        </>
      )}
    </ItemArea>
  );
};
export default AuthMenu;
