import * as React from "react";
import axios from "axios";
import styled from "styled-components";
import Container from "@mui/material/Container";
import DatePicker from "@mui/lab/DatePicker";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { toast } from "react-toastify";

const Section = styled.div`
  padding: 20px;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  margin-top: 20px;
  @media (max-width: 991px) {
    width: 100%;
    padding: 0px;
  }
`;
const DescriptionWrap = styled.div`
  @media (max-width: 991px) {
    margin: 0;
    padding: 0;
  }
  & h4 {
    font-size: 24px;
    font-weight: 500;
    color: var(--themeColor);
    margin-bottom: 20px;
  }

  & .button {
    background: #bd2323;
    border: 1px solid #dadada;
    border-radius: 1000px;
    padding: 15px 50px;
    font-size: 20px;
    justify-content: center;
    color: var(--bgColor);
    margin-bottom: 20px;
    text-transform: capitalize;
    @media (max-width: 991px) {
      width: 100%;
      padding: 15px 0px;
    }
    &:hover {
      background: #a30d0d;
    }
  }
`;
const Input = styled.input`
  width: 310px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #000;
  font-size: 16px;
  @media (max-width: 767px) {
    width: 100%;
  }
  &:focus-visible {
    border: 1px solid black;
    outline: -webkit-focus-ring-color auto 0;
  }
`;
const Form = styled.form``;
const FormWrap = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;
const InputArea = styled.div`
  width: 32%;
  & .datepicker {
    background-color: #e2e2e2;
    color: #b7b7b7;
    border: 0;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Label = styled.label`
  display: block;
  font-weight: normal;
  color: #444444;
  font-size: 20px;
  margin-bottom: 5px;
`;

const TextArea = styled.textarea`
  width: 60%;
  background-color: #fff;
  color: #000;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 40px;
  border: 1px solid #000;
  font-size: 16px;
  &:focus-visible {
    border: 1px solid black;
    outline: -webkit-focus-ring-color auto 0;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const QuoteWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  @media (max-width: 667px) {
    display: block;
  }
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const DetailsWram = styled.div`
  & h3 {
    margin: 0px;
  }
`;
const InfoWrap = styled.div``;
const TextWrap = styled.div`
  margin: 20px 0px;
  & textarea {
    min-width: 50%;
  }
`;
interface IDetails {
  task: any;
  lead?: any;
}
interface IValue {
  date: any;
  time: any;
  amount: any;
  details: string;
}
const deafultValue = {
  date: null,
  time: null,
  amount: 0,
  details: "",
};

const Description: React.FC<IDetails> = ({ task, lead }) => {
  const [formData, setFormData] = React.useState<IValue>(deafultValue);
  const [isLoader, setLoader] = React.useState(false);
  const [quotes, setQuote] = React.useState<any>([]);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}getOnsiteQuote/${lead.id}`).then((response) => {
      setQuote(response.data);
    });
  }, []);

  const submitForm = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const body = new FormData();
    body.append("date", formData.date);
    body.append("time", formData.time);
    body.append("amount", formData.amount);
    body.append("details", formData.details);
    body.append("leadID", lead.id);
    body.append("taskID", task.id);

    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}save-quote`, body, config)
        .then((response) => {
          if (response.data.status) {
            toast.success(response.data.msg);
            setFormData(deafultValue);
          } else {
            toast.error(response.data.msg);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Server error! Please try again.");
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      setLoader(false);
      toast.error(errors?.data);
    }
    console.log(formData);
  };
  //console.log(formData.time);

  return (
    <Section>
      <Container maxWidth="xl">
        <DescriptionWrap>
          <h4> Description </h4>
          {task.description}
          {quotes.length > 0 &&
            quotes.map((quote: any, index: number) => (
              <QuoteWrap>
                <DetailsWram>
                  <h3>
                    {quote.first_name} {quote.last_name}
                  </h3>
                  <InfoWrap>
                    <b>Quote amount :</b> {quote.quote_amount}, <b>Quote date :</b> {quote.quote_date} ,
                    <b>Quote time: </b>
                    {quote.quote_date}
                  </InfoWrap>
                  <p>
                    <b>Quote details : </b>
                    {quote.quote_details}
                  </p>
                </DetailsWram>
              </QuoteWrap>
            ))}
          <h4>Appointment for onsite quote</h4>
          <Form>
            <FormWrap>
              <InputArea>
                <Label>Date</Label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="datepicker"
                    value={formData.date}
                    onChange={(newValue) => {
                      setFormData({ ...formData, date: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} name="quote_date" />}
                  />
                </LocalizationProvider>
              </InputArea>

              <InputArea>
                <Label>Time</Label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    value={formData.time}
                    onChange={(newValue) => {
                      setFormData({ ...formData, time: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} name="quote_time" />}
                  />
                </LocalizationProvider>
              </InputArea>
              <InputArea>
                <Label>Quote amount</Label>
                <TextField
                  fullWidth
                  name="quote_amount"
                  value={formData.amount}
                  placeholder="$500"
                  onChange={(e: any) => {
                    setFormData({ ...formData, amount: e.target.value });
                  }}
                  style={{ width: "100%" }}
                />
                {/* <Input type="text" value={amount} name="amount" placeholder="$500" style={{ width: "100%" }} /> */}
              </InputArea>
            </FormWrap>
            <TextWrap>
              <Label>Quote details</Label>
              <TextareaAutosize
                name="quote_details"
                value={formData.details}
                aria-label="Quote details"
                minRows={6}
                placeholder="Quote details"
                onChange={(e: any) => {
                  setFormData({ ...formData, details: e.target.value });
                }}
              />
              {/* <TextArea value="" name="details" rows={6} /> */}
            </TextWrap>
            <Button onClick={submitForm} className="button">
              Upload written quote
            </Button>
          </Form>
        </DescriptionWrap>
      </Container>
    </Section>
  );
};
export default Description;
