import * as React from "react";
import { IMaskInput } from "react-imask";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Divider, Grid } from "@mui/material";

import CardInput from "./CardInput";

import card from "../../assets/img/card.png";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { id, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="text-center">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const PaymentArea = styled.div`
  background: #e3e3e3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 500px;
  padding: 30px 0;
`;
const PaymentType = styled.div`
  padding: 0 30px 15px;
`;

const PayNow: React.FC<any> = ({ openBuyLead, setOpenBuyLead, taskId, loadTaskDetails }) => {
  const handleClose = () => {
    setOpenBuyLead(false);
  };

  return (
    <div>
      <Dialog
        open={openBuyLead}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle id="alert-dialog-title" onClose={handleClose}>
          Select your payment method
        </BootstrapDialogTitle>

        <DialogContent>
          <PaymentArea>
            <PaymentType>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <RadioGroup aria-label="gender" name="controlled-radio-buttons-group" value="card">
                    <FormControlLabel value="card" control={<Radio />} label="Debit/credit card " />
                  </RadioGroup>
                </Grid>
                <Grid item xs={4}>
                  <img src={card} alt="" />
                </Grid>
              </Grid>
            </PaymentType>
            <Divider />

            <CardInput id={taskId} handleClose={handleClose} loadTaskDetails={loadTaskDetails} />
          </PaymentArea>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default PayNow;
