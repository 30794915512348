import { Redirect } from "react-router";
import styled from "styled-components";
import Container from "@mui/material/Container";
import Banner from "../../components/Banner";
import CategoryItem from "./CategoryItem";
import Review from "./Review";
import { connect } from "react-redux";

const Section = styled.div``;
const CategorySection = styled.div`
  background: #727272;
  padding-bottom: 100px;
`;
const Title = styled.h2`
  padding: 50px 0 80px;
  text-align: center;
  font-size: 36px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0;
  color: var(--bgColor);
`;

const Home: React.FC<any> = ({ isAuthencated }) => {
  if (isAuthencated) {
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <Section>
      <Banner />
      <Review />
      <CategorySection>
        <Container maxWidth="xl">
          <Title>Choose a Categoty</Title>
          <CategoryItem />
        </Container>
      </CategorySection>
    </Section>
  );
};
const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
});
export default connect(mapStateToProps, {})(Home);
