import styled from "styled-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import AboutImg from "../../assets/img/rv-img.png";

const Section = styled.div`
  padding: 150px 0;
  background-color: #c4c4c4;
  @media (max-width: 991px) {
    padding: 30px 0;
  }
`;
const Img = styled.img``;
const Title = styled.h3`
  font-size: 36px;
  color: var(--colorAlt);
  font-weight: 500;
  margin: 10px 0 30px;
  @media (max-width: 991px) {
    font-size: 24px;
    text-align: center;
  }
  & span {
    color: var(--bgColor);
  }
`;
const Para = styled.p`
  font-size: 18px;
  margin-bottom: 25%;
  color: #3e3e3e;
  @media (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 40px;
    text-align: center;
  }
  & span {
    color: black;
    font-weight: 700;
  }
`;
const ReviewBtn = styled.div`
  @media (max-width: 900px) {
    text-align: center;
  }
  & button {
    background-color: #000;
    border-radius: 40px;
    padding: 12px 40px;
    text-transform: capitalize;
    font-size: 24px;
    @media (max-width: 991px) {
      font-size: 18px;
      padding: 8px 35px;
    }
    &:hover {
      background-color: #302e2e;
    }
  }
`;

const Review = () => {
  return (
    <Section>
      <Container maxWidth="xl">
        <Grid container spacing={6} className="fld">
          <Grid item sm={12} md={6}>
            <Img src={AboutImg} alt="" />
          </Grid>
          <Grid item sm={12} md={6}>
            <Title>
              Invest <span> minutes </span> by making a quick video and save <span> hours </span> searching for and
              calling multiple businesses.
            </Title>
            <Para>
              If a picture is worth a thousand words, a video is worth ten thousand. To get quotes for your project,
              make a quick video, take a few pictures, and answer a few questions.
              <span> Request more than one quote. It is good to have more than one solution </span>. After you “submit”
              we will immediately text your video to Tulsa-area businesses who specialize in that field and they will
              contact you.
            </Para>
            <ReviewBtn>
              <Button variant="contained">Choose category</Button>
            </ReviewBtn>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
export default Review;
