import { IRegister, ILogin, UPDATE_REGISTER, RESET_REGISTER, UPDATE_LOGIN, RESET_LOGIN } from "./types";

export function updateRegister(newJob: IRegister) {
  return {
    type: UPDATE_REGISTER,
    payload: newJob,
  };
}

export function updateLogin(newJob: ILogin) {
  return {
    type: UPDATE_LOGIN,
    payload: newJob,
  };
}

export function resetRegister() {
  return {
    type: RESET_REGISTER,
  };
}

export function resetLogin() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  return {
    type: RESET_LOGIN,
  };
}
