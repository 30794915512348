import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Container from "@mui/material/Container";
import Banner from "./banner";
import Grid from "@mui/material/Grid";
import CardItem from "./cardItem";

const Section = styled.section``;
const PopularSection = styled.section`
  & h4 {
    color: var(--themeColor);
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & h4 {
    color: var(--themeColor);
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  & span {
    color: #2571e2;
  }
`;
const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  color: #2571e2;
  & a {
    text-decoration: none;
  }
`;

const YourLeads: React.FC<any> = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}getCardLeads`).then((response) => {
      setItems(response.data.items);
    });
  }, []);

  return (
    <Section>
      <Banner />
      <PopularSection>
        <Container maxWidth="xl">
          <TitleWrap>
            <h4>Your Leads</h4>
          </TitleWrap>
          <Grid container spacing={2}>
            {items.length > 0 &&
              items.map((item, index) => (
                <Grid item md={3} sm={6} xs={12} key={index}>
                  <CardItem history={props.history} item={item} />
                </Grid>
              ))}
          </Grid>
        </Container>
      </PopularSection>
    </Section>
  );
};
export default YourLeads;
