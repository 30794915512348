import * as React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { GoLinkExternal } from "react-icons/go";
import { BsTelephoneInbound } from "react-icons/bs";
import { FaRegAddressBook } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import RequestModal from "../../components/request";
import ContractInformationUpdate from "./modal/ContractInformationUpdate";

const QuateWrap = styled.div`
  & .quate-btn {
    margin-bottom: 30px;
    @media (max-width: 900px) {
      width: 60%;
      margin: 0 auto;
    }
    background-color: #c92b27;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 18px;
    &:hover {
      background-color: #931f1f;
    }
  }
`;

const AddressArea = styled.div`
  border: 1px solid #727272;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 900px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    & li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-bottom: 1px solid #727272;
      padding: 12px 0px;
      &:last-child {
        border-bottom: 0;
      }
      & .icon {
        font-size: 30px;
      }
      & .link {
        text-decoration: none;
        display: block;
      }
    }
  }
`;

const EditIcon = styled.button`
  color: #c92b27;
  background: #e9e9e9;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border: 0;
  & .edit-icon {
    color: #c92b27;
    font-size: 22px;
  }
`;
const DirectionWrap = styled.div``;
const Quote: React.FC<any> = ({ user, authLoadData }) => {
  const [open, setOpen] = React.useState(false);
  const [updateModal, setUpdateModal] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <QuateWrap>
      {user.user_type === "provider" && (
        <Button className="quate-btn" variant="contained" onClick={handleClickOpen}>
          Request Quote
        </Button>
      )}

      <AddressArea>
        <EditIcon onClick={() => setUpdateModal(true)}>
          <MdOutlineModeEditOutline className="edit-icon" />
        </EditIcon>
        <ul>
          {user.website && (
            <li>
              <a href={user.website} target="_blank" className="instagram" rel="noreferrer">
                {user.website}
              </a>
              <GoLinkExternal className="icon" />
            </li>
          )}
          {user.mobile_phone && (
            <li>
              <span>{user.mobile_phone}</span>
              <BsTelephoneInbound className="icon" />
            </li>
          )}
          {user.business_address && (
            <li>
              <DirectionWrap>
                <Link className="link" href="#">
                  Get Direction
                </Link>
                <span>{user.business_address}</span>
              </DirectionWrap>
              <FaRegAddressBook className="icon" />
            </li>
          )}
        </ul>
      </AddressArea>
      <RequestModal open={open} setOpen={setOpen} />
      <ContractInformationUpdate open={updateModal} setOpen={setUpdateModal} user={user} authLoadData={authLoadData} />
    </QuateWrap>
  );
};
export default Quote;
