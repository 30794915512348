import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import map from "../../assets/img/profile/map.png";
import { EditWrap, EditIcon } from "./style";
import { MdOutlineModeEditOutline } from "react-icons/md";
import LocationHoursModal from "./modal/LocationHoursModal";
import { refType } from "@mui/utils";

const LocationWrap = styled.div`
  & img {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const CustGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    display: block;
  }
`;
const AddressWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 320px) {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  & button {
    border: 1px solid #b4b4b4;
    color: var(--color);
    font-size: 18px;
    font-weight: 700;
  }
`;
const LeftAddress = styled.div`
  & p {
    margin: 0;
    color: black;
    font-size: 18px;
  }
`;
const Links = styled(Link)`
  font-size: 18px;
`;
const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
  @media (max-width: 900px) {
    float: none;
    margin-top: 10px;
  }
  & li {
    padding: 10px 0px;
    text-align: left;
    display: flex;
    color: black;
    @media (max-width: 900px) {
      justify-content: space-between;
    }
    & span {
      width: 60px;
      display: block;
      font-weight: 700;
      color: black;
    }
  }
`;
const getItemInfo = (items: any, name: string, type: string, deafult: any) => {
  if (items.length > 0) {
    const info = items.filter((src: any) => src.day === name)[0];
    return info[type] ? info[type] : deafult;
  } else {
    return deafult;
  }
};
const Location: React.FC<any> = ({ user, authLoadData }) => {
  const [items, setItems] = useState<any>([]);
  const deafultData: any = {
    address: user.address,
    lat: user.lat,
    lng: user.lng,
    monday: { name: "Monday", start: null, end: null, closed: false },
    tuesday: { name: "Tuesday", start: null, end: null, closed: false },
    wednesday: { name: "Wednesday", start: null, end: null, closed: false },
    thursday: { name: "Thursday", start: null, end: null, closed: false },
    friday: { name: "Friday", start: null, end: null, closed: false },
    saturday: { name: "Saturday", start: null, end: null, closed: false },
    sunday: { name: "Sunday", start: null, end: null, closed: false },
  };
  const [formData, setFormData] = React.useState<any>(deafultData);
  const [open, setOpen] = useState(false);

  const getServiceName = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios.get(`${process.env.REACT_APP_BACKEND_URL}getBusinessHour`, config).then((response) => {
      setItems(response.data);
      setFormData({
        address: user.address,
        lat: user.lat,
        lng: user.lng,
        monday: {
          name: "Monday",
          start: getItemInfo(response.data, "Monday", "start", null),
          end: getItemInfo(response.data, "Monday", "end", null),
          closed: getItemInfo(response.data, "Monday", "closed", false),
        },
        tuesday: {
          name: "Tuesday",
          start: getItemInfo(response.data, "Tuesday", "start", null),
          end: getItemInfo(response.data, "Tuesday", "end", null),
          closed: getItemInfo(response.data, "Tuesday", "closed", false),
        },
        wednesday: {
          name: "Wednesday",
          start: getItemInfo(response.data, "Wednesday", "start", null),
          end: getItemInfo(response.data, "Wednesday", "end", null),
          closed: getItemInfo(response.data, "Wednesday", "closed", false),
        },
        thursday: {
          name: "Thursday",
          start: getItemInfo(response.data, "Thursday", "start", null),
          end: getItemInfo(response.data, "Thursday", "end", null),
          closed: getItemInfo(response.data, "Thursday", "closed", false),
        },
        friday: {
          name: "Friday",
          start: getItemInfo(response.data, "Friday", "start", null),
          end: getItemInfo(response.data, "Friday", "end", null),
          closed: getItemInfo(response.data, "Friday", "closed", false),
        },
        saturday: {
          name: "Saturday",
          start: getItemInfo(response.data, "Saturday", "start", null),
          end: getItemInfo(response.data, "Saturday", "end", null),
          closed: getItemInfo(response.data, "Saturday", "closed", false),
        },
        sunday: {
          name: "Sunday",
          start: getItemInfo(response.data, "Sunday", "start", null),
          end: getItemInfo(response.data, "Sunday", "end", null),
          closed: getItemInfo(response.data, "Sunday", "closed", false),
        },
      });
    });
  };

  useEffect(() => {
    getServiceName();
  }, []);

  return (
    <div>
      <EditWrap>
        <h4>Location & hours</h4>
        <EditIcon onClick={() => setOpen(true)}>
          <MdOutlineModeEditOutline className="edit-icon" />
        </EditIcon>
      </EditWrap>
      <LocationWrap>
        <CustGrid container spacing="6">
          <Grid item md={6} sm={12}>
            <img src={map} alt="" />
            <AddressWrap>
              <LeftAddress>
                <Links href="#">{user.address}</Links>
                <p>Ste A</p>
              </LeftAddress>
              <Button variant="outlined">Get Directions</Button>
            </AddressWrap>
            <span>Walnut Creek, CA 94596</span>
          </Grid>
          <Grid item md={4} sm={12}>
            {items.length > 0 && (
              <UL>
                {items.map((item: any, index: number) => (
                  <li key={index}>
                    <span> Mon </span> 8:00AM - 5:00PM
                  </li>
                ))}
              </UL>
            )}
          </Grid>
        </CustGrid>
      </LocationWrap>
      <LocationHoursModal
        open={open}
        setOpen={setOpen}
        getServiceName={getServiceName}
        items={items}
        authLoadData={authLoadData}
        user={user}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};
export default Location;
