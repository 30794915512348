import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import Container from "@mui/material/Container";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FiCalendar } from "react-icons/fi";
import { BiTimeFive } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsBag } from "react-icons/bs";
import BuyerLead from "./BuyerLead";
import Task from "./Task";
import PayNow from "./PayNow";
import { connect } from "react-redux";
import moment from "moment";
import { FaRegStar } from "react-icons/fa";
import ReviewModal from "../../components/ReviewModal";
import ProgressBars from "../../components/ProgressBar";
import ProviderInfo from "./ProviderInfo";

const Section = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ContentWrapper = styled.div`
  height: 100%;
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 30px 40px;
  & span {
    margin: 0;
  }
  & h4 {
    font-size: 36px;
    font-weight: 500;
    color: var(--themeColor);
    margin: 0;
  }
  p {
    color: #727272;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`;
const CardWrap = styled.div`
  margin-bottom: 40px;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;
const Item = styled.div`
  background: #e8e8e8;
  border: 1px solid #dadada;
  border-radius: 1000px;
  padding: 10px 15px;
  display: flex;
  width: 225px;
  justify-content: flex-start;
  margin-bottom: 15px;
  @media (max-width: 991px) {
    width: 100%;
    padding: 10px 30px;
  }

  & .icon {
    margin-right: 15px;
    color: #2571e2;
    font-size: 25px;
    font-weight: 600;
  }
`;
const IconWrap = styled.div`
  & p {
    font-size: 14px;
    color: var(--themeColor);
    margin: 0;
  }
  & span {
    color: #2571e2;
    font-weight: 700;
    font-size: 14px;
  }
`;
const ButtonReview = styled(Button)`
  background: #bd2323;
  border: 1px solid #bd2323;
  border-radius: 100px;
  color: var(--bgColor);
  display: block;
  line-height: 20px;
  font-size: 18px;
  padding: 15px 40px;
  &:hover {
    background: #a30d0d;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 991px) {
    width: 100%;
    display: block;
  }

  & button {
    margin-right: 15px;
    background: #bd2323;
    border: 1px solid #dadada;
    border-radius: 100px;
    padding: 15px 40px 8px;
    display: flex;
    width: 225px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    color: var(--bgColor);
    display: block;
    line-height: 20px;
    & small {
      font-size: 14px;
      font-weight: 700;
    }
    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 10px;
    }

    &:hover {
      background: #a30d0d;
    }
  }
  & span {
    color: #bd2323;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 991px) {
      text-align: center;
      display: block;
    }
  }
`;

const Title = styled.h4`
  font-size: 24px;
  color: #727272;
  margin-bottom: 15px;
  font-weight: 400;
  margin: 0px 0px 20px 0px;
`;
const ImageWrapper = styled.div`
  border: 1px solid #bbbbbb;
  border-radius: 10px;
  display: flex;
  padding: 20px;
  height: 100%;
`;
const SmallImg = styled.div`
  width: 15%;
  margin-right: 10px;
  & img {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    &.active {
      border: 2px solid #bbbbbb;
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const LargeImg = styled.div`
  width: 85%;
  & img {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 100% !important;
  }
`;
const CustGrid = styled(Grid)`
  @media (max-width: 991px) {
    width: 100% !important;
    display: block !important;
  }
`;
const LeadDetails: React.FC<any> = (props) => {
  const taskId = props.match.params.id;
  const [task, setDetails] = useState<any>([]);
  const [leads, setLeads] = useState<any>([]);
  const [mainImage, setImage] = useState("");
  const [isLoader, setLoader] = React.useState(true);
  const [othersItem, setOthersItem] = useState([]);
  const [openBuyLead, setOpenBuyLead] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const handleClickOpenBuyLead = () => {
    setOpenBuyLead(true);
  };

  const loadTaskDetails = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}task-details/${taskId}`)
      .then((response) => {
        setDetails(response.data);
        setImage(response.data.video);
      })
      .finally(() => setLoader(false));
    axios.get(`${process.env.REACT_APP_BACKEND_URL}getLead/${taskId}`).then((response) => {
      setLeads(response.data);
    });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}others-task`).then((response) => {
      setOthersItem(response.data.items);
    });
    if (taskId) {
      loadTaskDetails();
    }
  }, [taskId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.isAuthencated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Section>
      {isLoader && <ProgressBars />}

      {task && (
        <Container maxWidth="xl">
          <Grid container spacing="12">
            <Grid item md={7} sm={12}>
              <ImageWrapper>
                <SmallImg>
                  <img
                    src={task.video_thumbnail}
                    onClick={() => setImage(task.video)}
                    className={mainImage === task.video ? "active" : ""}
                    alt=""
                  />
                  {task.images &&
                    task.images.map((img: any, index: number) => (
                      <img
                        src={img.image}
                        key={index}
                        onClick={() => setImage(img.image)}
                        className={mainImage === img.image ? "active" : ""}
                        alt=""
                      />
                    ))}
                </SmallImg>
                <LargeImg>
                  {mainImage.split(".")[mainImage.split(".").length - 1] === "mp4" ? (
                    <video src={mainImage} controls style={{ width: "100%" }} />
                  ) : (
                    <img src={mainImage} alt="" />
                  )}
                </LargeImg>
              </ImageWrapper>
            </Grid>
            <Grid item md={5} xs={12}>
              <ContentWrapper>
                {/* <span>New</span> */}
                <h4>{`${task.service_type} ${task.specific_service}`}</h4>
                <p>{task.description}</p>
                <CardWrap>
                  <CustGrid container spacing="6">
                    <Grid item md={6} sm={12}>
                      <Item>
                        <FiCalendar className="icon" />
                        <IconWrap>
                          <p>Quote deadline</p>
                          <span>{moment(task.deadline).format("ll")}</span>
                        </IconWrap>
                      </Item>

                      <Item>
                        <BiTimeFive className="icon" />
                        <IconWrap>
                          <p>Availability</p>
                          <span>{task.appointment_time}</span>
                        </IconWrap>
                      </Item>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Item>
                        <AiOutlineCheckCircle className="icon" />
                        <IconWrap>
                          <p>Status of project</p>
                          <span> {task.project_status}</span>
                        </IconWrap>
                      </Item>
                      <Item>
                        <BsBag className="icon" />
                        <IconWrap>
                          <p>Consumer wants</p>
                          <span> {task.quotes} Quotes</span>
                        </IconWrap>
                      </Item>
                    </Grid>
                  </CustGrid>
                </CardWrap>
                {props.user.user_type === "provider" && !task.isLead && (
                  <ButtonWrap>
                    <Button onClick={handleClickOpenBuyLead}>
                      Buyer Lead
                      <br />
                      <small>$9.95</small>
                    </Button>
                    <span>{task.quotes - leads.length} quotes remaining</span>
                    <PayNow
                      openBuyLead={openBuyLead}
                      setOpenBuyLead={setOpenBuyLead}
                      taskId={taskId}
                      loadTaskDetails={loadTaskDetails}
                    />
                  </ButtonWrap>
                )}
                {props.user.user_type === "consumer" && (
                  <Item
                    style={{
                      backgroundColor: "#b3b3b3",
                      justifyContent: "center",
                      color: "#fff",
                      textTransform: "uppercase",
                      fontWeight: 500,
                    }}
                  >
                    <>{leads.length} Provider(s)</>
                  </Item>
                )}
                {task.isLead > 0 && (
                  <>
                    <ButtonReview onClick={() => setOpenReviewModal(true)}>
                      <FaRegStar className="star" />
                      &nbsp; Write a Review
                    </ButtonReview>
                    <ReviewModal
                      setOpen={setOpenReviewModal}
                      open={openReviewModal}
                      taskId={taskId}
                      userId={task.user_id}
                    />
                  </>
                )}
              </ContentWrapper>
            </Grid>
          </Grid>
          {leads.length > 0 &&
            props.user.id === task.uid &&
            leads.map((lead: any, index: number) => (
              <BuyerLead lead={lead} userType={props.user.user_type} task={task} key={index} />
            ))}
          {task.isLead && leads.length > 0 && props.user.user_type === "provider" && (
            <ProviderInfo userId={task.user_id} userType={props.user.user_type} task={task} taskId={taskId} />
          )}
          {props.user.user_type === "provider" && <Task othersItem={othersItem} />}
        </Container>
      )}
    </Section>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
  user: state.login.user,
});
export default connect(mapStateToProps, {})(LeadDetails);
