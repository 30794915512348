import * as React from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";

const FormGroup = styled.div`
  margin-bottom: 30px;
`;

const Description: React.FC<any> = ({ formData, setFormData }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, description: (event.target as HTMLInputElement).value });
  };
  return (
    <div>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        Add more description and/or upload documents like an inspection report.
      </FormLabel>
      <FormGroup>
        <TextField label="" value={formData.description} onChange={handleChange} size="small" multiline rows={7} />
      </FormGroup>
    </div>
  );
};

export default Description;
