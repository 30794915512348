import * as React from "react";
import styled from "@emotion/styled";
import FormLabel from "@mui/material/FormLabel";
import DatePicker from "../DatePicker";

const FormGroup = styled.div`
  margin-bottom: 30px;
`;

const Deadline: React.FC<any> = ({ formData, setFormData }) => {
  const handleChange = (date: any) => {
    setFormData({ ...formData, deadline: date });
  };
  return (
    <div>
      <FormLabel className="font-24 theme-color mb-5" component="legend">
        What is the deadline to get your quote?
      </FormLabel>
      <FormGroup>
        <DatePicker label="" type="number" value={formData.deadline} changeDate={handleChange} />
      </FormGroup>
    </div>
  );
};

export default Deadline;
