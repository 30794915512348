export const ServiceTypeData = [
  {
    name: "Repair A/C",
    lable: "Repair",
  },
  {
    name: "Replace shingles",
    lable: "Installation / Replacement",
  },
];

export const specificServiceData = [
  {
    name: "Drain",
    lable: "Drain",
  },
  {
    name: "Toilet",
    lable: "Toilet",
  },
  {
    name: "Gas Line",
    lable: "Gas Line",
  },
  {
    name: "Shower",
    lable: "Shower",
  },
  {
    name: "Sewer",
    lable: "Sewer",
  },
  {
    name: "Garbage Disposal",
    lable: "Garbage Disposal",
  },
  {
    name: "Faucet",
    lable: "Faucet",
  },
  {
    name: "Sink",
    lable: "Sink",
  },
  {
    name: "Bathtub",
    lable: "Bathtub",
  },
  {
    name: "Water Heater",
    lable: "Water Heater",
  },
  {
    name: "Others",
    lable: "Others",
  },
];

export const numberofQuets = [
  {
    name: 1,
    lable: 1,
  },
  {
    name: 2,
    lable: 2,
  },
  {
    name: 3,
    lable: 3,
  },
  {
    name: 4,
    lable: 4,
  },
  {
    name: 5,
    lable: 5,
  },
  {
    name: 6,
    lable: 6,
  },
  {
    name: 7,
    lable: 7,
  },
  {
    name: 8,
    lable: 8,
  },
  {
    name: 9,
    lable: 9,
  },
  {
    name: 10,
    lable: 10,
  },
];
export const projectStatusJson = [
  {
    name: "Ready to hire",
    lable: "Ready to hire",
  },
  {
    name: "Planning",
    lable: "Planning",
  },
];
export const contactedJson = [
  {
    name: "Text",
    lable: "Text",
  },
  {
    name: "Phone",
    lable: "Phone",
  },
  {
    name: "Email",
    lable: "Email",
  },
];
export const appointmentTimeJson = [
  {
    name: "Morning",
    lable: "Morning",
  },
  {
    name: "Afternoon",
    lable: "Afternoon",
  },
  {
    name: "Evening",
    lable: "Evening",
  },
  {
    name: "Varies",
    lable: "Varies",
  },
];
