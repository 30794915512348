import * as React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled as styledd } from "@mui/material/styles";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import Category from "./Category";
import ServiceType from "./ServiceType";
import SpecificService from "./SpecificService";
import Emergency from "./Emergency";
import Address from "./Address";
import Video from "./Video";
import Picture from "./Picture";
import Description from "./Description";
import Quotes from "./Quotes";
import ProjectStatus from "./ProjectStatus";
import Contacted from "./Contacted";
import AppointmentTime from "./AppointmentTime";
import Deadline from "./Deadline";

const BootstrapDialog = styledd(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const Form = styled.form``;
const FormInner = styled.div`
  width: 100%;
  margin-bottom: 40px;
  min-height: 40vh;
`;
const PageLoader = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="model-cus-title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const deafultData = {
  category: "",
  serviceType: "",
  // specificService: "",
  // emergency: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  video: "",
  images: [],
  description: "",
  quotes: "",
  projectStatus: "",
  contacted: "",
  appointmentTime: "",
  deadline: "",
};

const RequestModal: React.FC<any> = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const { open, setOpen } = props;
  const [isLoader, setLoader] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState<any>(deafultData);

  const handleClose = () => {
    setOpen(false);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => {
      if (activeStep === 0) {
        if (formData.category === "") {
          toast.error("Category is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 1) {
        if (formData.serviceType === "") {
          toast.error("Service type is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 2) {
        if (formData.street === "") {
          toast.error("Street is required.");
          return prevActiveStep;
        } else if (formData.city === "") {
          toast.error("City is required.");
          return prevActiveStep;
        } else if (formData.state === "") {
          toast.error("State is required.");
          return prevActiveStep;
        } else if (formData.zip === "") {
          toast.error("Zip is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 3) {
        if (formData.video === "") {
          toast.error("Video is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 4) {
        if (formData.images === "") {
          toast.error("Image is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 5) {
        // if (formData.description === "") {
        //   toast.error("Description is required.");
        //   return prevActiveStep;
        // }
        return prevActiveStep + 1;
      } else if (activeStep === 6) {
        if (formData.quotes === "") {
          toast.error("Quotes is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 7) {
        if (formData.projectStatus === "") {
          toast.error("Project status is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 8) {
        if (formData.contacted === "") {
          toast.error("Contacted is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 9) {
        if (formData.appointmentTime === "") {
          toast.error("Appointment time is required.");
          return prevActiveStep;
        }
        return prevActiveStep + 1;
      } else if (activeStep === 10) {
        if (formData.deadline === "") {
          toast.error("Deadline is required.");
          return prevActiveStep;
        }
        submitForm();
        return prevActiveStep;
      } else {
        return prevActiveStep + 1;
      }
    });
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const submitForm = () => {
    setLoader(true);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const date = new Date(`${formData.deadline}`);

    const body = new FormData();
    body.append("category", formData.category);
    body.append("serviceType", formData.serviceType);
    // body.append("specificService", formData.specificService);
    // body.append("emergency", formData.emergency);
    body.append("street", formData.street);
    body.append("city", formData.city);
    body.append("state", formData.state);
    body.append("zip", formData.zip);
    body.append("description", formData.description);
    body.append("quotes", formData.quotes);
    body.append("projectStatus", formData.projectStatus);
    body.append("contacted", formData.contacted);
    body.append("appointmentTime", formData.appointmentTime);
    body.append("deadline", date.toUTCString());
    body.append("video", formData.video);
    if (formData.images) {
      for (const key of Object.keys(formData.images)) {
        body.append("images[]", formData.images[key]);
      }
    }

    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}task-post`, body, config)
        .then((response) => {
          if (response.data.status) {
            videoConvert();
            setOpen(false);
            toast.success(response.data.msg);
          } else {
            response.data.msg.forEach((error: string) => toast.error(error));
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Server error! Please try again.");
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      setLoader(false);
      toast.error(errors?.data);
    }
  };

  const videoConvert = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const body = JSON.stringify({});
    try {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}videoConvert`, body, config)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          toast.error(err.request.statusText);
        })
        .finally(() => {
          setLoader(false);
          history.push("/task-list");
        });
    } catch (error: any) {
      const errors = error.response.data.errors;
      console.log(errors);
    }
  };

  return (
    <div>
      {isLoader && (
        <PageLoader>
          <CircularProgress size={60} thickness={4} color="inherit" />
        </PageLoader>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Request a quote
        </BootstrapDialogTitle>
        <DialogContent>
          <Form>
            <FormInner>
              {activeStep === 0 && <Category handleNext={handleNext} formData={formData} setFormData={setFormData} />}
              {activeStep === 1 && (
                <ServiceType handleNext={handleNext} formData={formData} setFormData={setFormData} />
              )}
              {/* {activeStep === 2 && (
                <SpecificService handleNext={handleNext} formData={formData} setFormData={setFormData} />
              )}
              {activeStep === 3 && <Emergency handleNext={handleNext} formData={formData} setFormData={setFormData} />} */}
              {activeStep === 2 && <Address formData={formData} setFormData={setFormData} />}
              {activeStep === 3 && <Video handleNext={handleNext} formData={formData} setFormData={setFormData} />}
              {activeStep === 4 && <Picture handleNext={handleNext} formData={formData} setFormData={setFormData} />}
              {activeStep === 5 && <Description formData={formData} setFormData={setFormData} />}
              {activeStep === 6 && <Quotes handleNext={handleNext} formData={formData} setFormData={setFormData} />}
              {activeStep === 7 && (
                <ProjectStatus handleNext={handleNext} formData={formData} setFormData={setFormData} />
              )}
              {activeStep === 8 && <Contacted handleNext={handleNext} formData={formData} setFormData={setFormData} />}
              {activeStep === 9 && (
                <AppointmentTime handleNext={handleNext} formData={formData} setFormData={setFormData} />
              )}
              {activeStep === 10 && <Deadline formData={formData} setFormData={setFormData} />}
            </FormInner>
          </Form>
        </DialogContent>
        <DialogActions>
          <MobileStepper
            variant="progress"
            steps={11}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 900, flexGrow: 1, width: "100%", padding: "0" }}
            nextButton={
              <Button variant="contained" size="large" onClick={handleNext}>
                {activeStep === 10 ? "Finish" : "Next"}
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button variant="contained" size="large" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default RequestModal;
