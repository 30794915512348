import React from "react";
import styled from "styled-components";
import axios from "axios";
import { Redirect } from "react-router";
import Container from "@mui/material/Container";
import { connect } from "react-redux";
import Banner from "./Banner";
import Photos from "./Photos";
import Service from "./Service";
import Location from "./Location";
import About from "./About";
import Review from "./Review";
import MoreInfo from "./MoreInfo";
import Grid from "@mui/material/Grid";
import { AiOutlineArrowRight } from "react-icons/ai";

const Section = styled.section`
  margin-top: 40px;
`;
const CompanyNameArea = styled.section``;
const PhotosSection = styled.section`
  padding-bottom: 20px;
  border-bottom: 1px solid #b4b4b4;
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--colorAlt);
    margin: 0;
  }
  & span {
    display: flex;
    align-items: center;
  }
`;

const ServiceSection = styled.section`
  padding-bottom: 30px;
  border-bottom: 1px solid #b4b4b4;
  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--colorAlt);
    margin: 0;
  }
`;

const AboutSection = styled.section`
  padding-bottom: 30px;
  border-bottom: 1px solid #b4b4b4;
  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--colorAlt);
    margin-bottom: 30px;
    margin-top: 15px;
  }
`;
const LocationSection = styled.section`
  padding-bottom: 30px;
  border-bottom: 1px solid #b4b4b4;
  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--colorAlt);
    margin: 0;
  }
`;
const MoreInfoSection = styled.section`
  padding-bottom: 30px;
  border-bottom: 1px solid #b4b4b4;
  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--colorAlt);
    margin: 0;
  }
`;

const ReviewSection = styled.section`
  padding-bottom: 30px;
  border-bottom: 1px solid #b4b4b4;
  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--colorAlt);
    margin-bottom: 30px;
    margin-top: 15px;
  }
`;
const EditIcon = styled.button`
  color: #c92b27;
  background: #e9e9e9;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border: 0;
  & .edit-icon {
    color: #c92b27;
    font-size: 22px;
  }
`;
const EditWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const PublicProfile: React.FC<any> = (props) => {
  const userId = props.match.params.id;
  const [user, setUser] = React.useState<any>([]);
  const [isLoader, setLoader] = React.useState(true);

  React.useEffect(() => {
    setLoader(true);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const body = JSON.stringify({ id: userId });
    if (userId) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}getUserInfo`, body, config).then((response) => {
        setUser(response.data);
      });
    }
  }, [userId]);

  if (!props.isAuthencated) {
    return <Redirect to={{ pathname: "/" }} />;
  }
  return (
    <Section>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            {user.user_type === "provider" && (
              <>
                <CompanyNameArea>
                  <Banner user={user} />
                </CompanyNameArea>

                {/* <PhotosSection>
                  <TitleWrap>
                    <h4>Photos & videos</h4>
                    <span>
                      See all 18 photos &nbsp; <AiOutlineArrowRight />
                    </span>
                  </TitleWrap>
                  <Photos />
                </PhotosSection>
                <ServiceSection>
                  <Service />
                </ServiceSection> */}
              </>
            )}

            <AboutSection>
              {user.user_type === "provider" && <h4>About the business</h4>}
              <About user={user} />
            </AboutSection>
            {user.user_type === "provider" && (
              <>
                <LocationSection>
                  <Location user={user} />
                </LocationSection>
                <MoreInfoSection>
                  <MoreInfo />
                </MoreInfoSection>
              </>
            )}

            <ReviewSection>
              <h4>Recommended Reviews</h4>
              <Review />
            </ReviewSection>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthencated: state.login.isAuthencated,
});
export default connect(mapStateToProps, {})(PublicProfile);
