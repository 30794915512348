import * as React from "react";
import styled from "styled-components";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import { FaRegStar } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import CompanyNameUpdate from "./modal/CompanyNameUpdate";

const BannerWrap = styled.div`
  border-bottom: 1px solid #b4b4b4;
  & h2 {
    font-size: 48px;
    font-weight: 700;
    color: var(--colorAlt);
    margin: 0;

    @media (max-width: 991px) {
      text-align: center;
    }
  }
`;

const RatingWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 35px;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    text-align: center;
    display: block;
  }
  & .rating {
    color: #fcea46;
    font-size: 35px;
    margin-left: 15px;
    margin-right: 15px;
  }
  & .review-num {
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
    @media (max-width: 400px) {
      display: block;
    }
  }
`;
const RatingBtn = styled.div`
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & button {
    background-color: #c92b27;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 18px;
    &:hover {
      background-color: #931f1f;
    }
  }
  & .star {
    color: #fff;
    font-size: 18px;
  }
`;
const EditIcon = styled.button`
  color: #c92b27;
  background: #e9e9e9;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border: 0;
  & .edit-icon {
    color: #c92b27;
    font-size: 22px;
  }
`;
const EditWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Banner: React.FC<any> = ({ user, authLoadData }) => {
  const [value, setValue] = React.useState<number | null>(4);
  const [open, setOpen] = React.useState(false);

  return (
    <BannerWrap>
      <EditWrap>
        <h2>{user.company_name}</h2>
        <EditIcon onClick={() => setOpen(true)}>
          <MdOutlineModeEditOutline className="edit-icon" />
        </EditIcon>
      </EditWrap>

      <RatingWrap>
        5.0
        <Rating
          className="rating"
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        />
        <span className="review-num">100 Reviews</span>
      </RatingWrap>
      {/* <RatingBtn>
        <Button variant="contained">
          <FaRegStar className="star" />
          &nbsp; Write a Review
        </Button>
      </RatingBtn> */}
      <CompanyNameUpdate open={open} setOpen={setOpen} user={user} authLoadData={authLoadData} />
    </BannerWrap>
  );
};
export default Banner;
