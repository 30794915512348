import React from "react";
import styled from "styled-components";
import { AiTwotoneStar } from "react-icons/ai";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

const Section = styled.section``;
const PopularWrap = styled.section`
  & .card {
    border-radius: 10px;
    margin-bottom: 20px;
  }
`;

const UserWrap = styled.div`
  pading: 15px;
`;
const ImgWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & img {
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    height: 40px;
  }
  & a {
    color: var(--colorAlt);
    text-decoration: none;
  }
`;
const RatingArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .rating {
    color: #f0de3f;
    margin-right: 8px;
  }
  & button {
    background-color: #bd2323;
    text-transform: capitalize;
    width: 120px;
    height: 35px;
    border-radius: 50px;
    &:hover {
      background-color: #8f1616;
    }
  }
`;
const SeeBtn = styled(Link)`
  background-color: #bd2323;
  text-transform: capitalize;
  width: 120px;
  height: 35px;
  border-radius: 50px;
  text-align: center;
  display: block;
  padding: 5px;
  color: #fff;
  text-decoration: none;
  &:hover {
    background-color: #8f1616;
  }
`;
const Ratings = styled.div`
  color: #f0de3f;
  display: flex;
  align-items: center;
`;
const Pragraph = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 50px;
`;
const PopularItem: React.FC<any> = ({ item }) => {
  return (
    <Section>
      <PopularWrap>
        <Card className="card">
          <CardMedia component="img" alt="green iguana" height="180" image={item.video_thumbnail} />
          <CardContent>
            <UserWrap>
              <ImgWrap>
                <img src={item.photo} alt="" />
                <Link to={`/profile/${item.user_id}`}>{item.name}</Link>
              </ImgWrap>
              <Pragraph>{item.description}</Pragraph>
              <RatingArea>
                <Ratings>
                  <AiTwotoneStar className="rating" />
                  <span>5.0</span>
                </Ratings>
                <SeeBtn to={`/lead/${item.id}`}>See more</SeeBtn>
              </RatingArea>
            </UserWrap>
          </CardContent>
        </Card>
      </PopularWrap>
    </Section>
  );
};
export default PopularItem;
